import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images

import NotificationIcon from "../../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import leachureImage from "../../assets/images/all/leachure.png";
import ProfileImage from "../../assets/images/all/profiel.png";
import SearchIcon from "../../assets/images/all/search.svg";

import { Footer } from "../../components";
import { uploadIcon } from "@progress/kendo-svg-icons";
import  TeacherSidebar from "../../components/TeacherSidebar";

const TeacherChannelBoost1 = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container-fluid cust_class">
        <div className="row">
          {/* Sidebar */}
          <TeacherSidebar/>
       

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 boost_bg cust_row_righgt">
                <div className="row mt-5 mb-2">
                    <div className="col-md-9 ps-0">
                        <div className="search-box dashboard_search">
                            <input type="text"
                                className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                                placeholder="Search"/>
                            <button type="submit" className="btn btn-main spl_burron  border-0"><img
                                    src={SearchIcon} alt=""/></button>
                        </div>
                    </div>
                    <div className="col-md-2 text-xl-center d-none  d-lg-block mt-3">
                        <img src={NotificationIcon} alt=""/>
                    </div>
                    <div className="col-md-1 d-none d-lg-block">
                        <div className="dashboard_profile ms-auto">
                            <img src={ProfileImage} alt=""/>
                        </div>
                    </div>
                </div>
             
                <div className="row px-0 py-4">
                    <div className="col-sm-12">
                        <h2 className="learm-tilte mb-4">Course Curriculum</h2>


                        <div className="row">
                            
                            <div className="col-sm-3 col-md-4 col-lg-4 col-xl-2">
                                <div className="boost_profile text-center mx-auto mx-lg-0">
                                    <img src={leachureImage} alt="How to Solve Physics Problems"
                                        className="img-cover w-100 h-100"/>
                                </div>

                            </div>
                           
                            <div className="col-sm-9 col-md-8 col-lg-8 col-xl-10">
                             
                                <div className="row ps-3 ps-lg-0">
                                    <div className="col-sm-12 order-lg-0">
                                        <div className="d-flex justify-content-center justify-content-lg-between">
                                            <div className="text-center text-lg-start">
                                                <h4 className="learm-tilte mb-0">Akhil Menon</h4>
                                                <span className="learm-tilte2">30k Followers</span>
                                            </div>
                                            <div className="text-end psotion_absoltu ">
                                                <span className="learm-tilte2">Manage</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-sm-12 order-lg-2 mt-3 mt-lg-0">
                                        <p className="course-description  text-center text-lg-start course-description_1 text-muted mt-xl-3">
                                            Simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                            has been
                                            the industry's standard dummy text ever since the 1500s, when an unknown
                                            printer
                                            took a galley of type and scrambled it to make a type specimen book.
                                        </p>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div className="col-sm-12 my-4">
                            <div className="progress">

                                <div className="progress-bar bg-main" role="progressbar" style={{ width: '65%' }}
                                    aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">
                                </div>
                            </div>
                        </div>

                        <div className="row gy-3">


<div className="col-sm-12 mt-4 text-center">
    <p className="learm-tilte font-36">N/A</p>
    <p className="learm-tilte2">Estimated audience size</p>
</div>
<div className="col-sm-12 mt-4">
   
    <div className="position-relative">
        <input type="text" className="common-input common-input--md dash_borad_input_boost"
               id="location" placeholder="Location" maxlength="60"/>
    </div>
</div>

<div className="col-sm-12 mt-4">
   
    <div className="position-relative">
        <input type="number" className="common-input common-input--md dash_borad_input_boost"
               id="minimumAge" placeholder="Minimum Age" min="0"/>
    </div>
</div>


</div>
                    </div>
                </div>


                <div className="row mb-5 mt-3">
                    <div className="col-sm-12 text-end">
                        <a href="#" className="btn btn_cousre py-2 px-4">Next</a>
                    </div>
                </div>

            </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeacherChannelBoost1;

import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images

import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import ProfileImage from "../../assets/images/all/profiel.png";

import ChatBoxBackImage from "../../assets/images/icons/back_chatbox.svg";
import SendIcon from "../../assets/images/icons/send-svgrepo-com 1.svg";
import { Footer } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import GlobalSearch from "../../components/GlobalSearch";

const StudentChatbot = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <StudentSidebar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
           <GlobalSearch/>

            {/* Additional Content */}
            <div className="row align-items-center my-3">
              <div className="col-7 d-flex" onClick={()=>navigate('/DashboardChat')}>
                <img src={ChatBoxBackImage} alt="Chat Back" />
              </div>
              <div className="col-5 text-end">
                <span className="learm-tilte2 font-14 font-lg-16 me-0">Requests (6)</span>
                <img src={ManageEditIcon} alt="Manage Edit" />
              </div>
            </div>

            {/* Example Chat Box */}
            <div className="row gy-3 p-0 mb-5">
              <div className="dashboard_card border-5">
                {/* Chat Header with Profile */}
                <div className="row chat_row align-items-center border-5">
                  <div className="col-2 col-sm-1">
                    <div className="chat_icons mx-auto position-relative">
                      <img src={ProfileImage} alt="Profile" />
                      <div className="circle_dot"></div>
                    </div>
                  </div>
                  <div className="col-10 col-sm-11">
                    <p className="course_video_time">Sarah Solomon</p>
                  </div>
                </div>

                {/* Chat Messages */}
                <div className="p-4 d-flex flex-column">
                  <div className="chat_bubble chat_bubble_left">Simply dummy text of the printing</div>
                  <div className="chat_bubble chat_bubble_left">Simply dummy text of the printing standard</div>
                  <div className="chat_bubble chat_bubble_left">Text ever since the 1500</div>
                  <div className="chat_bubble chat_bubble_right">When an unknown printer took when an unknown</div>
                  <div className="chat_bubble chat_bubble_right">
                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s
                  </div>
                  <div className="chat_bubble chat_bubble_right">An unknown</div>
                </div>

                {/* Chat Input Box */}
                <div className="col-md-12 py-3">
                  <div className="search-box dashboard_search">
                    <input
                      type="text"
                      className="common-input common-input--lg dashboard_chatbox border-0 shadow-sm auto-suggestion-input"
                      placeholder="Type your message..."
                      style={{ height: "64px" }}
                    />
                    <button type="submit" className="btn btn-main spl_burron border-0">
                      <img src={SendIcon} alt="Send" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default StudentChatbot;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears } from "./../Utils";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images
import DropDownIcon from "../../assets/images/icons/drop-down-svgrepo-com 1.svg";
import EditIcon from "../../assets/images/icons/edit-4-svgrepo-com 1.svg";
import qrImage from '../../assets/images/all/qr_code.png'
import EditImage from "../../assets/images/icons/edit-1-svgrepo-com.svg";
import teacherImage from '../../assets/images/icons/teacehr_+.svg';
import experinceImage from '../../assets/images/all/experencied_icon.png'

import EditActiveIcon from "../../assets/images/icons/edit-4-svgrepo-com_active.svg";
import CourseIcon from "../../assets/images/icons/course-diary-svgrepo-com.svg";
import CourseActiveIcon from "../../assets/images/icons/course-diary-svgrepo-com_active.svg";
import UploadIcon from "../../assets/images/icons/upload-square-svgrepo-com 1.svg";
import UploadActiveIcon from "../../assets/images/icons/upload-square-svgrepo-com_active.svg";
import AssignmentIcon from "../../assets/images/icons/return-order-svgrepo-com 1.svg";
import AssignmentActiveIcon from "../../assets/images/icons/return-order-svgrepo-com_active.svg";
import CertificateIcon from "../../assets/images/icons/certificate-check-svgrepo-com 1.svg";
import CertificateActiveIcon from "../../assets/images/icons/certificate-check-svgrepo-com_active.svg";
import MessageIcon from "../../assets/images/icons/email-envelope-letter-mail-message-svgrepo-com 1.svg";
import MessageActiveIcon from "../../assets/images/icons/email-envelope-letter-mail_active.svg";
import NotificationIcon from "../../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import ProfileImage from "../../assets/images/all/profiel.png";
import SearchIcon from "../../assets/images/all/search.svg";
import HistoryIconActive from "../../assets/images/icons/history-svgrepo-com_active.svg";
import HistoryIcon from "../../assets/images/icons/history-svgrepo-com 1.svg";
import FeedImage from "../../assets/images/all/fedd.png";


const TeacherProfile = () => {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [profileimg, setProfileimg] = useState(""); // State to hold roles data
  const [bioInfoDetails, setbioInfoDetails] = useState("");
  const [personalInfoDetails, setpersonalInfoDetails] = useState("");
  const [teacherExperience, setteacherExperience] = useState([]);
  const [errors, setErrors] = useState({});
  const [profileDataEditable, setprofileDataEditable] = useState(false);
  const [bioInfoEditable, setBioInfoEditable] = useState(false);
  const [teacherExperienceEditable, setTeacherExperienceEditable] = useState(false);
  const [profileData, setProfileData] = useState({
    full_name: "",
    email_id: "",
    username: "",
    location: "",
  });
  const [bioInfo, setBioInfo] = useState({
    bio_info: "",
  });
  const urlToShare = "https://example.com"; // URL to be shared
  const message = "Name: "+ profileData.full_name  + "   Email Id: "+profileData.email_id;


  useEffect(() => {
    fetchProfile();
    fetchBioInfoDetails();
    fetchPersonalInfoDetails();
    fetchTeacherExperience();
  }, []); // Empty dependency array to run only on mount

  const fetchProfile = async () => {
    const user_id = ACCESS_Log_In_User_Id;
    axios.get(`${API_BASE_URL}/getprofileImgDetails?user_id=${user_id}`)
      .then(function (response) {
        // console.log(response.data.code);
        if (response.data.code == 200) {
          //console.log(response.data.data[0]);
          setProfileimg(response.data.data[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const shareToFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      urlToShare
    )}`;
    window.open(facebookUrl, "_blank");
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message + "  " + urlToShare
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  const shareToTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      message
    )}&url=${encodeURIComponent(urlToShare)}`;
    window.open(twitterUrl, "_blank");
  };

  const updateProfileImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileimg(file.name); // Update the profile image with the uploaded file
      // Prepare the FormData object for the POST request
      const formData = new FormData();
      formData.append('profile_image_name', file); // Append the image file to FormData
      formData.append('role_id', ACCESS_Role_ID);
      formData.append('user_id', ACCESS_Log_In_User_Id);

      try {
        // Use FormData in the POST request to send the file correctly
        let id = null;
        if (profileimg && profileimg.hasOwnProperty('id')) {
          id = profileimg['id'] ?? null;
        }
        axios.post(`${API_BASE_URL}/createUpdateprofileImg/${id}`, formData)
          .then((response) => {
            console.log('Profile image uploaded successfully:', response.data);
            fetchProfile(); // Fetch updated profile info
          })
          .catch((error) => {
            console.error('Error saving profile:', error);
          });
      } catch (error) {
        console.error('Error in uploading profile:', error);
      }
    }
  };

  const fetchBioInfoDetails = async () => {
    const user_id = ACCESS_Log_In_User_Id;
    axios.get(`${API_BASE_URL}/getbioInfoDetails?user_id=${user_id}`)
      .then(function (response) {
        //console.log(response);
        if (response.data.code == 200) {
          // console.log(response.data.data[0]);
          //setbioInfoDetails(response.data.data[0]);
          setBioInfo({
            id: response.data.data[0]["id"],
            bio_info: response.data.data[0]['bio_info'],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const BioInfoEdit = async () => {
    if (bioInfoEditable) {
      try {
        const id = bioInfo['id'] ?? null;
        // Assuming the API endpoint is 'https://example.com/api/profile'
        const response = await axios.post(`${API_BASE_URL}/createUpdatebioInfo/${id}`, {
          user_id: ACCESS_Log_In_User_Id,
          role_id: ACCESS_Role_ID,
          bio_info: bioInfo.bio_info
        });

        // Assuming the API returns the updated profile data
        console.log('Profile updated:', response.data);
        // Optionally update the state with the new data
        setProfileData(response.data);
      } catch (error) {
        console.error('Error saving profile:', error);
      }
    }
    // Toggle the edit mode
    setBioInfoEditable(!bioInfoEditable);
    fetchBioInfoDetails();
    fetchPersonalInfoDetails();
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setBioInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const fetchPersonalInfoDetails = async () => {
    const user_id = ACCESS_Log_In_User_Id;
    axios.get(`${API_BASE_URL}/getpersonalInfoDetails?user_id=${user_id}`)
      .then(function (response) {
        console.log(response);
        if (response.data.code == 200) {
          console.log(response.data.data[0]);
          //setpersonalInfoDetails(response.data.data[0]);
          setProfileData({
            id: response.data.data[0]["id"],
            full_name: response.data.data[0]["full_name"],
            email_id: response.data.data[0]["email_id"],
            username: response.data.data[0]["username"],
            location: response.data.data[0]["location"],
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const personalInfoEdit = async () => {
    if (profileDataEditable) {
      try {
        // Assuming the API endpoint is 'https://example.com/api/profile'
        const id = profileData['id'] ?? null;
        const response = await axios.post(`${API_BASE_URL}/createUpdatepersonalInfoDetails/${id}`, {
          user_id: ACCESS_Log_In_User_Id,
          role_id: ACCESS_Role_ID,
          full_name: profileData.full_name,
          email_id: profileData.email_id,
          username: profileData.username,
          location: profileData.location
        });

        // Assuming the API returns the updated profile data
        console.log('Profile updated:', response.data);
        // Optionally update the state with the new data
        setProfileData(response.data);
      } catch (error) {
        console.error('Error saving profile:', error);
      }
    }
    // Toggle the edit mode
    setprofileDataEditable(!profileDataEditable);
    fetchPersonalInfoDetails();
    fetchBioInfoDetails();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchTeacherExperience = async () => {
    const user_id = ACCESS_Log_In_User_Id;
    axios.get(`${API_BASE_URL}/getTeacherExperienceUserId?user_id=${user_id}`)
      .then(function (response) {
        console.log(response);
        if (response.data.code == 200) {
          console.log(response.data.data);
          setteacherExperience(response.data.data);
          console.log(teacherExperience);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Check out this awesome content!",
          text: "Here is some amazing content I found.",
          url: "https://example.com", // Your URL to share
        });
        console.log("Shared successfully!");
      } else {
        alert("Web Share API is not supported in your browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };
  


  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <div className="col-sm-3 dashboard_right d-none d-xl-block">
            <div className="padding_100" onClick={() => navigate('/DashboardChat')}>
              <img src={DropDownIcon} alt="Back" />
              <span className="ms-2">Back</span>
            </div>
            <div className="dashboard-sidebar">
              <button type="button" className="dashboard-sidebar__close d-lg-none d-flex">
                <i className="las la-times"></i>
              </button>
              <div className="dashboard-sidebar__inner">
                <ul className="sidebar-list">
                  <li className="sidebar-list__item activePage">
                    <a href="dashboard.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={EditIcon} alt="Edit Profile" className="icon" />
                        <img src={EditActiveIcon} alt="Edit Profile Active" className="icon icon-active" />
                      </span>
                      <span className="text">Edit Profile</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentProfileCourse" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CourseIcon} alt="Courses" className="icon" />
                        <img src={CourseActiveIcon} alt="Courses Active" className="icon icon-active" />
                      </span>
                      <span className="text">Courses</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardPost" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={UploadIcon} alt="Posts" className="icon" />
                        <img src={UploadActiveIcon} alt="Posts Active" className="icon icon-active" />
                      </span>
                      <span className="text">Posts</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentOrderAssign" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={AssignmentIcon} alt="Assignments Order" className="icon" />
                        <img src={AssignmentActiveIcon} alt="Assignments Order Active" className="icon icon-active" />
                      </span>
                      <span className="text">Assignments Order</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentCertificate" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CertificateIcon} alt="Certificates" className="icon" />
                        <img src={CertificateActiveIcon} alt="Certificates Active" className="icon icon-active" />
                      </span>
                      <span className="text">Certificates</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardChat" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={MessageIcon} alt="Messages" className="icon" />
                        <img src={MessageActiveIcon} alt="Messages Active" className="icon icon-active" />
                      </span>
                      <span className="text">Messages</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="dashboard-profile.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={HistoryIcon} alt="History" className="icon" />
                        <img src={HistoryIconActive} alt="History Active" className="icon icon-active" />
                      </span>
                      <span className="text">History</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>



          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5 ">
            <div className="row mt-5">
              <div className="col-md-9 ps-0">
                <div className="search-box dashboard_search">
                  <input type="text"
                    className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                    placeholder="Search" />
                  <button type="submit" className="btn btn-main spl_burron  border-0"><img
                    src={SearchIcon} alt="" /></button>
                </div>
              </div>
              <div className="col-md-2 text-xl-center  mt-3">
                <img src={NotificationIcon} alt="" />
              </div>
              <div className="col-md-1">
                <div className="dashboard_profile ms-auto">
                  <img src={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : ''} alt="" />
                </div>
              </div>
            </div>
            <p className="learm-tilte my-3">Edit Profile</p>

            <div className="row p-0 mb-5">
              <div className="dashboard_card p-0">
                <div className="row align-items-center p-4">
                  <div className="col-sm-4 col-lg-4 col-xl-2">
                    <div className="dasboard_profile mx-auto">
                      <img src={profileimg && profileimg.profile_image_path ? `${Image_Base_Url}${profileimg.profile_image_path}` : ''} alt="Profile Image" className="rounded-circle" />
                    </div>

                  </div>
                  <div className="col-sm-4 col-lg-8 col-xl-8 text-center text-lg-start">
                    <button className="btn btn-outline-light my-2 my-lg-0" onClick={() => document.getElementById("fileInput").click()}>Upload new photo</button>
                    <input type="file" id="fileInput" accept="image/*" style={{ display: "none" }}
                      onChange={updateProfileImage} />
                    <p className="mt-2 profiel_text text-start">At least 800x800 px recommended.
                      JPG or PNG is allowed</p>
                  </div>
                  <div className="col-sm-4 col-lg-8 col-xl-2 mt-4 mt-sm-0">
                    <div
                      className="dasboard_profile_qr mx-auto d-flex justify-content-center align-items-center"  onClick={() => setShowOptions(!showOptions)} style={{ cursor: "pointer" }}>
                      <img src={qrImage} alt="Profile Image" className="" />
                    </div>

                    {showOptions && (
        <div
          style={{
            position: "absolute",
            top: "10%",
            background: "#fff",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            padding: "10px",
            borderRadius: "8px",
            zIndex: 10,
            
          }}
        >
         <button
    style={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "10px",
      margin: "5px 0",
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
    }}
    onClick={shareToFacebook}
  >
    <img
      src="https://img.icons8.com/fluency/48/000000/facebook-new.png"
      alt="Facebook"
      style={{ width: "24px", marginRight: "10px" }}
    />
    Share to Facebook
  </button>
  <button
    style={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "10px",
      margin: "5px 0",
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
    }}
    onClick={shareToWhatsApp}
  >
    <img
      src="https://img.icons8.com/color/48/000000/whatsapp.png"
      alt="WhatsApp"
      style={{ width: "24px", marginRight: "10px" }}
    />
    Share to WhatsApp
  </button>
  <button
    style={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "10px",
      margin: "5px 0",
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
    }}
    onClick={shareToTwitter}
  >
    <img
      src="https://img.icons8.com/fluency/48/000000/twitter.png"
      alt="Twitter"
      style={{ width: "24px", marginRight: "10px" }}
    />
    Share to Twitter
  </button>
        </div>
      )}
                  </div>
                </div>
                <hr className="dashboard_line" />
                <div className="row py-4 px-5">
                  <div className="dashboard_sub_card">
                    <div className="row px-0 px-sm-3">
                      <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Personal Info</p>
                      </div>
                      <div className="col-sm-4 col-5 text-end">
                        <button onClick={personalInfoEdit}>
                          <img src={EditIcon} alt="Edit" /> {profileDataEditable ? "Save" : "Edit"}
                        </button>

                      </div>
                      <div className="col-sm-12 d-flex justify-content-between flex-column flex-lg-row mt-4">
                        <div className="">
                          <p className="">Full Name</p>
                          {profileDataEditable ? (
                            <input
                              type="text"
                              name="full_name"
                              value={profileData['full_name']}
                              onChange={handleChange}
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            <p className="profile_text_1 mt-2">{profileData['full_name']}</p>
                          )}
                        </div>
                        <div className="">
                          <p className="">Email</p>
                          {profileDataEditable ? (
                            <input
                              type="email"
                              name="email_id"
                              value={profileData.email_id}
                              onChange={handleChange}
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            <p className="profile_text_1 mt-2">{profileData.email_id}</p>
                          )}
                        </div>
                        <div className="">
                          <p className="">Username</p>
                          {profileDataEditable ? (
                            <input
                              type="text"
                              name="username"
                              value={profileData.username}
                              onChange={handleChange}
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            <p className="profile_text_1 mt-2">{profileData.username}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-12 mt-4">
                        <p className="profile_text_2 mb-3">Location</p>

                        <div className="position-relative">
                          {profileDataEditable ? (
                            <input
                              type="text"
                              name="location"
                              value={profileData.location}
                              onChange={handleChange}
                              className="common-input dashboard_editable_input"
                            />
                          ) : (
                            
                            <span class="input-icon input-icon--left text-main">{profileData.location}

                              
                            </span>
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-5 pb-4">
                  <div className="dashboard_sub_card">
                    <div className="row px-0 px-sm-3">
                      <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Bio</p>
                      </div>
                      <div className="col-sm-4 col-5 text-end">
                        <button onClick={BioInfoEdit}>
                          <img src={EditIcon} alt="Edit" />
                          {bioInfoEditable ? "Save" : "Edit"}
                        </button>

                      </div>
                      <div className="col-sm-12 mt-4">
                        {bioInfoEditable ? (
                          <textarea
                            name="bio_info"
                            value={bioInfo.bio_info}
                            onChange={handleChange1}
                            className="common-input dashboard_editable_input"
                            rows="4"
                          ></textarea>
                        ) : (
                          <p>{bioInfo.bio_info}</p>
                        )}
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-5 pb-4">
                  <div className="dashboard_sub_card">
                    <div className="row px-0 px-sm-3">
                      <div className="col-7 col-sm-8">
                        <p className="profile_text_2">Experience</p>
                      </div>
                      <div className="col-sm-4 col-5 text-end">
                        <button className="me-2">

                          <img src={teacherImage} alt="" />

                        </button>
                        <button>

                          <img src={EditImage} alt="" />

                        </button>

                      </div>

                      {teacherExperience.map((item, i) => (
                        <div className="row gy-2 align-items-start">
                          <div className="col-2 col-sm-1">
                            <div className="teacher_experince mx-auto position-relative">
                              <img src={experinceImage} alt="Profile Image"
                                className="" />
                            </div>

                          </div>
                          <div className="col-8 col-sm-6 mt-0 ps-0">
                            <p className="learm-tilte2 font-16 fw-500">{item.job_tital}</p>
                            <p className="course_video_time fw-500">{item.company_name}</p>
                            <p className="fw-500">{formatDate(item.joining_date)} - {formatDate(item.leaving_date)} | {calculateDifference(item.joining_date, item.leaving_date)}<br />
                              {item.location} , {item.country} | {item.job_position}
                            </p>
                          </div>

                        </div>
                      ))}
                     
                    </div>
                  </div>
                </div>
              </div>


            </div>

          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeacherProfile;

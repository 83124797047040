import React from 'react';
import { Link,useLocation  } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';

import GoogleImage from '../../src/assets/images/icons/google-pay-icon 1.svg';
import UpiImage from '../../src/assets/images/icons/upi-ar21 1.svg';
import SearchImage from '../../src/assets/images/all/search.svg';
import LogoImage from '../../src/assets/images/all/scan-svgrepo-com 1.svg';
import unionImage from '../../src/assets/images/icons/Union-1.svg';
import PaytmImage from '../../src/assets/images/icons/paytm-icon 1.svg'
import PhonePayImage from '../../src/assets/images/icons/phonepe-icon 1.svg'
import CardImage from '../../src/assets/images/icons/card-svgrepo-com 1.svg'
import { Navbar } from '../components';

function CourseCheckout() {
    const location = useLocation();
  const amount = location.state?.amount; // Retrieve the amount from state
    return (
        <div>
            {/* Progress Wrap */}
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>

            {/* Mobile Menu */}
            <div className="mobile-menu d-lg-none d-block">
                <button type="button" className="close-button">
                    <i className="las la-times"></i>
                </button>
                <div className="mobile-menu__inner">
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flx-align nav-menu--mobile">
                            <li className="nav-menu__item activePage">
                                <Link to="/" className="nav-menu__link">Home</Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/course" className="nav-menu__link">Courses</Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/feed" className="nav-menu__link">Feed</Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/videos" className="nav-menu__link">Videos</Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/teachers" className="nav-menu__link">Teachers</Link>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/institutes" className="nav-menu__link">Institutes</Link>
                            </li>
                            <li className="nav-menu__item has-submenu">
                                <Link to="#" className="nav-menu__link">My Profile</Link>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <Link to="/edit-profile" className="nav-submenu__link">Edit Profile</Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/enrolled-courses" className="nav-submenu__link">Enrolled Courses</Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/posts" className="nav-submenu__link">Your Posts</Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/certificates" className="nav-submenu__link">Certificates</Link>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <Link to="/chats" className="nav-submenu__link">Chats</Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-menu__item">
                                <Link to="/" className="nav-menu__link">Scan OR Code</Link>
                            </li>
                        </ul>
                        <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                            <Link to="/login" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</Link>
                        </div>
                    </div>
                </div>
            </div>

            <main className="change-gradient">
                {/* Header */}
                {/* <header>
                    <div className="logo"></div>
                
                    <div className="header-menu d-lg-block d-none">
                        <ul className="nav-menu flx-align">
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Home</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="course.html" className="nav-menu__link">Courses</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Feed</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Learn with us</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Teachers</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Institute</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">About Us</a>
                            </li>
                        </ul>
                    </div>
                    
                    <div className="header-right flx-align pe-lg-5">
                        <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
                            <a href="/" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</a>
                            <a href="/" className="btn cicrle_btn btn-main">
                                <img src={LogoImage} alt="Logo" />
                            </a>
                        </div>
                        <button type="button" className="toggle-mobileMenu d-lg-none">
                            <i className="las la-bars"></i>
                        </button>
                    </div>
                </header> */}

                <Navbar/>

                {/* Hero Section */}
                <section className="hero section-bg z-index-1">
                    <div className="container py-5 container-two">
                        <div className="row align-items-center gy-sm-5 gy-4">
                            <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                                <div className="position-relative">
                                    <div className="search-box">
                                        <input
                                            type="text"
                                            className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                                            placeholder="Search"
                                        />
                                        <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                            <img src={SearchImage} alt="Search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Course Details Section */}
                <section className="pb-5">
                    <div className="container cours_deataisl">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-sm-10 col-md-12">
                                <div className="row my-4">
                                    <div className="col-lg-7 col-xl-7 order-0 order-md-1">
                                        <h4 className="course-checkout">Checkout</h4>
                                        <p>Choose Payment Mode</p>
                                        <div className="common-accordion my-4" id="accordionExample">
                                            {/* UPI Payment Option */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <span className="me-3"><img src={UpiImage} alt="UPI Icon" /></span>
                                                        Pay Via UPI
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse show"
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                
                                                <div class="col-12">
                                                    <div class="form-check mb-3">
                                                        <input class="form-check-input" type="radio"
                                                            name="paymentMethod" id="googlePay" value="googlePay"/>
                                                        <label class="form-check-label d-flex align-items-center"
                                                            for="googlePay">
                                                            <img src={GoogleImage}
                                                                alt="Google Pay" width="24" class="me-2"/>
                                                            Google Pay
                                                        </label>
                                                    </div>
                                                    <div class="form-check mb-3">
                                                        <input class="form-check-input" type="radio"
                                                            name="paymentMethod" id="paytm" value="paytm"/>
                                                        <label class="form-check-label d-flex align-items-center"
                                                            for="paytm">
                                                            <img src={PaytmImage} alt="Paytm"
                                                                width="24" class="me-2"/>
                                                            Paytm
                                                        </label>
                                                    </div>
                                                    <div class="form-check mb-3">
                                                        <input class="form-check-input" type="radio"
                                                            name="paymentMethod" id="phonePe" value="phonePe"/>
                                                        <label class="form-check-label d-flex align-items-center"
                                                            for="phonePe">
                                                            <img src={PhonePayImage} alt="PhonePe"
                                                                width="24" class="me-2"/>
                                                            Phone Pe
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </div>

                                            {/* Card Payment Option */}
                                            <div class="accordion-item">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                                <span class="me-3"><img src={CardImage}
                                                        alt=""/></span>
                                                Card
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse"
                                            aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <form action="#" class="spl_form">
                                                    <div class="row gy-2">
                                                        <div class="col-12">
                                                            <div class="position-relative">
                                                                <input type="text" class="form-control common-input common-input--bg" placeholder="Card Number"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="position-relative">
                                                                <input type="text" class="form-control common-input common-input--bg" placeholder="Name on card"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="position-relative">
                                                                <input type="text" class="form-control common-input common-input--bg" placeholder="Valid Thru (MM/YY)"/>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="position-relative">
                                                                <input type="text" class="form-control common-input common-input--bg" placeholder="CVV"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-5 col-xl-4 offset-xl-1 order-0 order-md-1 p-4 p-sm-0">
                                        <div className="card card_add mb-3 p-md-2 bg-transparent">
                                            <h4 className="course-checkout">Checkout</h4>
                                            <div className="row check_class">
                                                <div className="col-6">
                                                    <p>order-1</p>
                                                    <p>order-1</p>
                                                </div>
                                                <div className="col-6 text-end">
                                                    <p>₹499</p>
                                                    <p>₹499</p>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-6">
                                                    <span className="course-orgian course-titles" style={{ fontSize: '18px' }}>Total</span>
                                                </div>
                                                {amount !== undefined ? (
                                                <div className="col-6 text-end">
                                                    <span className="course-orgian course-titles" style={{ fontSize: '18px' }}>₹{amount}</span>
                                                </div>
                                                ) : (
                                                    <p>No amount provided.</p>
                                                  )}
                                            </div>
                                            <div className="card-body p-0">
                                                <div className="text-md-center mt-3">
                                                    <Link to="#" className="btn btn_cousre w-100">Check Out</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            {/* Footer */}
            <footer className="footer-section">
                <img className="shape_top_10 d-lg-block d-none" src={unionImage} alt="Decoration Shape" />
                <div className="container container-two">
                    <div className="row gy-sm-5">
                        <div className="col-xl-6 col-sm-6">
                            <div className="position-relative mb-5">
                                <div className="search-box">
                                    <input type="text" className="common-input common-input--lg pill shadow-sm auto-suggestion-input" placeholder="Search" />
                                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                        <img src={SearchImage} alt="Search Icon" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5 col-xs-6">
                                    <div className="footer-widget">
                                        <ul className="footer-lists">
                                            <li><Link to="/" className="footer-lists__link">Home</Link></li>
                                            <li><Link to="/courses" className="footer-lists__link">Courses</Link></li>
                                            <li><Link to="/feed" className="footer-lists__link">Feed</Link></li>
                                            <li><Link to="/teachers" className="footer-lists__link">Teachers</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6 ps-xl-5">
                                    <div className="footer-widget">
                                        <ul className="footer-lists">
                                            <li><Link to="/about-us" className="footer-lists__link">About Us</Link></li>
                                            <li><Link to="/payment" className="footer-lists__link">Payment</Link></li>
                                            <li><Link to="/profile" className="footer-lists__link">My Profile</Link></li>
                                            <li><Link to="/institutes" className="footer-lists__link">Institutes</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footer-widget mt-4">
                                    <h3>Follow us on</h3>
                                    <div className="footer-widget__social">
                                        <ul className="social-icon-list">
                                            <li><a href="https://www.facebook.com" className="social-icon-list__link flx-center"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="https://www.youtube.com" className="social-icon-list__link flx-center"><i className="fab fa-youtube"></i></a></li>
                                            <li><a href="https://www.linkedin.com" className="social-icon-list__link flx-center"><i className="fab fa-linkedin-in"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 col-sm-6">
                            <div className="footer-widget">
                                <div className="map-box mb-30 mt-5">
                                    <li><Link to="/" className="footer-lists__link">Location</Link></li>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                                        width="100%" height="300" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default CourseCheckout;

import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "./Utils";
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';
import StarImage from '../../src/assets/images/all/star-svgrepo-com.svg'
import CardImage from '../../src/assets/images/course/image-1.png'
import SearchImage from '../../src/assets/images/all/search.svg'
import LogoImage from '../../src/assets/images/all/scan-svgrepo-com 1.svg'
import leftTopImage from '../../src/assets/images/all/shape_1.png'
import RightImage from '../../src/assets/images/icons/Unions.svg'
import { debounce } from 'lodash'; // Optional for debouncing

const Course = () => {
  const [course, setCourse] = useState([]); // State to hold roles data
  const [createdBy, setCreatedBy] = useState([]);
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [averageRatings, setAverageRatings] = useState([]);
  // Search Start
  const [query, setQuery] = useState('');
  const [searchTeachers, setSearchTeachers] = useState([]);
  const [searchCourses, setSearchCourses] = useState([]);
  const [searchInstitutes, setSearchInstitutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  // Search End
  const styles = {
    shapeTop: {
      // position: 'absolute',
      top: '-100px',
      left: '-100px',
    },

  };
  // Search Start 

  // Handle search input change
  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  // Debounced search function to limit API calls
  const handleSearch = debounce(async () => {
    if (!query.trim()) return;

    setLoading(true);
    setError('');

    try {
      const response = await axios.get(API_BASE_URL + `/courseSearch?query=${query}`);
      console.log(response.data.data);
      // setTeachers(response.data.teachers);
      setSearchCourses(response.data.data);
      // setInstitutes(response.data.institutes);
    } catch (err) {
      setError('An error occurred while searching.');
    } finally {
      setLoading(false);
    }
  }, 500); // Debounce delay of 500ms

  // Trigger search on key press (after debouncing)
  useEffect(() => {
    handleSearch();
  }, [query]); // Trigger handleSearch when the query changes
  // Handle course click to navigate to the detail page
  const handleCourseClick = (courseId) => {
    navigate(`/CourseDetail/${courseId}`); // Navigate to the course detail page
  };

  // Search End
  useEffect(() => {
    const url = API_BASE_URL + '/getCoursesDetails'
    const fetchRoles = async () => {
      try {

        const response = await axios.get(url);
        setCourse(response.data); // Set roles data to state
        setLoading(false); // Set loading to false
      } catch (err) {
        setError(err.message); // Handle error
        setLoading(false); // Set loading to false
      }
    };

    fetchRoles(); // Call the fetch function
  }, []); // Empty dependency array to run only on mount
  useEffect(() => {
    const fetchCreatedBy = async () => {
      try {
        // Create an array of promises for each product API call
        const promises = course.map(item =>
          axios.get(`${API_BASE_URL}/getCoursesCreatedBy?user_id=${item.user_id}`)
        );

        // Wait for all the promises to resolve
        const responses = await Promise.all(promises);

        const products = responses.map(response => response.data);
        const users = products.map(products => products.data);

        setCreatedBy(users);

      } catch (err) {
        // Handle errors
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCreatedBy();
  }, [course]); // Run the effect when cartItems changes

  // Rating start
  const fetchRatings = async () => {
    setLoading(true);
    try {
      const promises = course.map(item =>
        axios.get(`${API_BASE_URL}/ratings/course/${item.id}`)
      );
      const responses = await Promise.all(promises);
      const courserating = responses.map(response => response.data.data);
      const averageRatingsss = courserating.map((ratingsArray) => {
        return calculateAverageRating(ratingsArray);
      });

      //setRatings(courserating);
      setAverageRatings(averageRatingsss);
      renderStars(averageRatingsss)
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRatings();
  }, [course]);

  const handleRating = async (star, courseDetail_id) => {
    setRating(star);
    setcourseDetailId(courseDetail_id);
    await axios.post(API_BASE_URL + '/ratings', {
      user_id: ACCESS_Log_In_User_Id, // Replace with actual user id
      courseDetail_id: courseDetail_id,
      rating: star,
    });
    fetchRatings(); // Refresh ratings
  };
  const calculateAverageRating = (ratingsArray) => {
    const totalRating = ratingsArray.reduce((sum, ratingObj) => sum + ratingObj.rating, 0);
    return (totalRating / ratingsArray.length).toFixed(2); // Return average rounded to 2 decimal places

  };

  // Rating end
  return (
    <div>
      {/* ==================== Scroll to Top End Here ==================== */}
      <div style={styles.progressWrap} className="progress-wrap">
        <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>
      {/* ==================== Scroll to Top End Here ==================== */}

      {/* ==================== Mobile Menu Start Here ==================== */}
      <div style={styles.mobileMenu} className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button"> <i className="las la-times"></i> </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/Feed" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="javascript:void(0)" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="" className="nav-submenu__link"> Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="" className="nav-submenu__link"> Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="" className="nav-submenu__link"> Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="" className="nav-submenu__link"> Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="" className="nav-submenu__link"> Chats</a>
                  </li>
                </ul>
              </li>
              <li className="nav-menu__item">
                <a href="" className="nav-menu__link">Scan OR Code</a>
              </li>
            </ul>
            <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
              <a href="" className="btn btn-main pill sign_btn px-sm-4">
                Signup/Login
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Mobile Menu End Here ==================== */}

      {/* <img
         
          className="shape_top d-lg-block d-none"
          src={leftTopImage}
          alt=""
      />
      <img
          
          className="shape_top_6 d-xl-block d-none"
          src={RightImage}
          alt=""
      /> */}

      <main className="change-gradient">

        {/* <header style={styles.header}>
              <div className="logo"></div>
              
              <div className="header-menu d-lg-block d-none ">
                  <ul className="nav-menu flx-align">
                      <li className="nav-menu__item">
                          <a href="/" className="nav-menu__link">Home</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="/course" className="nav-menu__link">Courses</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="/Feed" className="nav-menu__link">Feed</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="" className="nav-menu__link">Learn with us</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="/teacher" className="nav-menu__link">Teachers</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="" className="nav-menu__link">Institute</a>
                      </li>
                      <li className="nav-menu__item">
                          <a href="" className="nav-menu__link">About Us</a>
                      </li>
                  </ul>
              </div>
            
              <div className="header-right flx-align pe-lg-5">
                  <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
                      <a href="" className="btn btn-main pill sign_btn px-sm-4">
                          Signup/Login
                      </a>
                      <a href="" className="btn cicrle_btn btn-main">
                          <img src={LogoImage} alt="" />
                      </a>
                  </div>
                  <button type="button" className="toggle-mobileMenu d-lg-none">
                      <i className="las la-bars"></i>
                  </button>
              </div>
           
          </header> */}
        <Navbar />


        <section className="hero section-bg z-index-1">
          <div className="container py-5 container-two">
            <div className="row align-items-center gy-sm-5 gy-4">
              <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                <div className="position-relative">
                  <div className="search-box">
                    {/* <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                      placeholder="Search"
                    />
                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                      <img src={SearchImage} alt="" />
                    </button> */}
                    <input
                      type="text"
                      value={query}
                      onChange={handleChange}
                      placeholder="Search for courses"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                    />

                    {loading && <p>Loading...</p>}
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    {searchCourses.length > 0 ? (
                      <ul>
                        {searchCourses.map((course) => (
                          <li key={course.id} onClick={() => handleCourseClick(course.id)}>
                            {course.course_title} - {course.course_description}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No courses found</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-5">
          {course.map((item, i) => (
            <div className="container">
              <div className="row justify-content-center">
                {/* Right Side: Card List */}
                {/* {Array.from({ length: 3 }).map((_, index) => ( */}
                <div className="col-lg-10 col-sm-10 col-md-12" key={i}>
                  {/* Card with updated content */}
                  <div className="spl_cards card mb-3">
                    <div className="row g-0">
                      {/* Image on the left for desktop, on top for mobile */}
                      <div className="col-md-4">
                        <img
                          src={`${Image_Base_Url}${item.course_image_path}`}
                          alt="Course"
                          className="img-cover w-100 h-100 rounded-start"
                        />
                      </div>
                      {/* Content on the right for desktop, below for mobile */}
                      <div className="col-md-8">
                        <div className="card-body">
                          {/* Title of the course */}
                          <h4 className="course-title">{item.course_title}</h4>

                          {/* Flex container: description (80% mobile) and rating (20% mobile) */}
                          <div className="row">
                            {/* Course description (100% width in desktop, 80% width in mobile) */}
                            <div className="col-8 col-sm-12 my-lg-3">
                              <p className="course-description text-muted">
                                {item.course_description}
                              </p>
                            </div>

                            <div className="col-4 col-sm-4">
                              {/* Rating (20% in mobile, stays to the right in mobile view) */}
                              <div className="course-rating d-flex align-items-center text-end">
                                {/* {Array.from({ length: 5 }).map((_, starIndex) => (
                                <span className="Stars me-1" title="5 Stars" key={starIndex}>
                                  <img src={StarImage} alt="5 stars" />
                                </span>
                              ))} */}
                                {ACCESS_TOKEN_NAME ? (
                                  [...Array(5)].map((_, index) => (
                                    //i === index ? ( // Use a ternary operator to conditionally render
                                    <span
                                      key={index + 1}
                                      onClick={() => handleRating(index + 1, item.id)} // Function to handle user click
                                      style={{
                                        cursor: 'pointer',
                                        color: index + 1 <= rating ? '#FFD700' : '#ccc', // Filled star if rating is equal or higher
                                        fontSize: '24px',
                                      }}
                                    >
                                      ★
                                    </span>
                                    // ) : null // Return null if the condition is not met
                                  ))
                                ) : (
                                  averageRatings.map((item, index) => {
                                    if (i == index) {
                                      const numberValue = item === "NaN" ? NaN : parseFloat(item);
                                      return (
                                        <div>
                                          {isNaN(numberValue) ? renderStars(0) : renderStars(numberValue)}
                                        </div>
                                      );
                                    }
                                  })
                                )}
                              </div>
                            </div>



                            {createdBy.map((innerArray, i) => (
                              i === 0 && innerArray.map(created => (
                                <div className="col-7 col-sm-4">
                                  <span className="course-author">Created by:{created.name}</span>
                                </div>
                              ))
                            ))}
                            <div className="col-5 col-sm-4">
                              <span className="course-time text-end text-md-start ms-3">{item.course_duration} Hours</span>
                            </div>
                          </div>

                          {/* Price Button centered */}
                          <div className="text-md-end mt-3">
                            <Link className="btn btn_cousre" to={`/CourseDetail/${item.id}`}>
                              ₹ {item.course_price}
                            </Link>
                            {/* <a href="#" className="btn btn_cousre"></a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End of Course Card */}
                </div>
                {/* ))} */}
              </div>
            </div>
          ))}
        </section>

        <Footer />


      </main>
    </div>
  );
};

export default Course;


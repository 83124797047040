



import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import QrScanner from "qr-scanner";

const QrReader = () => {
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [scannedResult, setScannedResult] = useState("");

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Navigate to the home screen
  };

  const onScanSuccess = (result) => {
    console.log(result);
    setScannedResult(result?.data);
    window.open(result?.data, '_blank');
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
    if (videoEl.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
      });

      scanner.current
        .start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (scanner.current) {
        scanner.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.");
  }, [qrOn]);

  // Handle image upload for QR scanning from gallery
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      QrScanner.scanImage(file)
        .then(result => {
          setScannedResult(result);
          window.open(result, '_blank');
        })
        .catch(err => {
          console.log("QR code scan failed:", err);
          alert("Failed to scan QR code. Try another image.");
        });
    }
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#fff',
      position: 'relative',
    }}>
      {/* Close button */}
      <svg onClick={handleClick} style={{
        position: 'absolute',
        top: '20px',
        right: '20px',
        cursor: 'pointer',
      }} xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
        <circle cx="25.7765" cy="27.3898" r="20.9444" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd"
          d="M53.1654 29.0006C53.1654 42.3474 42.3455 53.1673 28.9987 53.1673C15.6518 53.1673 4.83203 42.3474 4.83203 29.0006C4.83203 15.6538 15.6518 4.83398 28.9987 4.83398C42.3455 4.83398 53.1654 15.6538 53.1654 29.0006ZM21.6753 21.6773C22.3831 20.9695 23.5307 20.9695 24.2386 21.6773L28.9987 26.4373L33.7586 21.6774C34.4664 20.9695 35.6141 20.9695 36.3219 21.6774C37.0298 22.3852 37.0298 23.5328 36.3219 24.2405L31.5618 29.0006L36.3219 33.7605C37.0298 34.4684 37.0298 35.616 36.3219 36.3239C35.6141 37.0317 34.4664 37.0317 33.7586 36.3239L28.9987 31.564L24.2386 36.3239C23.5308 37.0317 22.3832 37.0317 21.6754 36.3239C20.9675 35.616 20.9675 34.4684 21.6754 33.7608L26.4353 29.0006L21.6753 24.2405C20.9675 23.5327 20.9675 22.3851 21.6753 21.6773Z"
          fill="url(#paint0_linear_425_3279)" />
        <defs>
          <linearGradient id="paint0_linear_425_3279" x1="15.3043" y1="8.86176" x2="44.3043" y2="51.5562"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA34F" />
            <stop offset="1" stopColor="#FF7A00" />
          </linearGradient>
        </defs>
      </svg>

      {/* Scanner area with camera feed */}
      <div style={{
        position: 'relative',
        width: '250px',
        height: '250px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '20px',
        border: '6px solid #D9D9D9',
        boxSizing: 'border-box',
        marginBottom: '30px',
      }}>
        <video ref={videoEl} style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}></video>
      </div>

      {/* Upload from Gallery button */}
      <p style={{
        color: '#999',
        fontSize: '14px',
        marginBottom: '15px',
      }}>Scan View</p>
      <label htmlFor="upload" style={{
        display: 'inline-block',
        background: 'linear-gradient(93deg, #FFA553 5.03%, #FF7A00 100%)',
        padding: '10px 20px',
        fontSize: '16px',
        borderRadius: '8px',
        cursor: 'pointer',
        color: '#000',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        textAlign: 'center',
        transition: 'background 0.3s ease',
      }}>
        Upload from Gallery
        <input
          type="file"
          id="upload"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />
      </label>

      {/* Show scanned result if available */}
      {scannedResult && (
        <p style={{ color: "#000", marginTop: "20px", fontSize: "16px" }}>
          Scanned Result: {scannedResult}
        </p>
      )}
    </div>
  );
};

export default QrReader;




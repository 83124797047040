import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Importing assets

import CourseImage from "../../assets/images/course/image-1.png";
import SearchImage from "../../assets/images/all/search.svg";
import StarImage from "../../assets/images/all/star-svgrepo-com.svg";

import { Footer } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import GlobalSearch from "../../components/GlobalSearch";

const StudentProfileCourse = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Edit Profile</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Enrolled Courses</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Your Posts</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Certificates</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Chats</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

     
    {/* <header class="header">
        <div class="container container-full">
            <nav class="header-inner flx-between">
             
                <div class="logo">

                </div>
                
                <div class="header-right flx-align pe-lg-5">
                    <button type="button" class="toggle-mobileMenu d-lg-none"> <i class="las la-bars"></i> </button>
                </div>
              
            </nav>
        </div>
    </header> */}
 
      {/* Main Container */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
         <StudentSidebar/>

          {/* Main Content */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
          <GlobalSearch/>

            <p className="learm-tilte my-3">Enrolled Courses</p>

            {/* Example Course Card */}
            <div className="spl_cards card mb-3 p-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={CourseImage} alt="Course" className="img-cover w-100 rounded" style={{ minHeight: "250px" }} />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-2">
                    <h4 className="profile_text_2">How to Solve Physics Problems</h4>
                    <p class="course-description text-muted ">
                                            Simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                            been the industry's standard dummy text ever since the 1500s, when an
                                            unknown printer took a galley of type and scrambled it to make a type
                                            specimen book.
                                        </p>
                    <div className="row m-0 p-0 my-1">
                      <div className="col-6"><span>65% Completed</span></div>
                      <div className="col-6 text-end"><span>65% Completed</span></div>
                    </div>
                    <div className="col-8 col-sm-12 my-1">
                      <div className="progress">
                        <div className="progress-bar bg-main" role="progressbar" style={{ width: "65%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="text-md-start mt-3">
                      <a href="#" className="btn btn_cousre px-5 py-2">₹ 999</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="spl_cards card mb-3 p-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={CourseImage} alt="Course" className="img-cover w-100 rounded" style={{ minHeight: "250px" }} />
                </div>
                <div className="col-md-8">
                  <div className="card-body pt-2">
                    <h4 className="profile_text_2">How to Solve Physics Problems</h4>
                    <p class="course-description text-muted ">
                                            Simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                                            been the industry's standard dummy text ever since the 1500s, when an
                                            unknown printer took a galley of type and scrambled it to make a type
                                            specimen book.
                                        </p>
                    <div className="row m-0 p-0 my-1">
                      <div className="col-6"><span>65% Completed</span></div>
                      <div className="col-6 text-end"><span>65% Completed</span></div>
                    </div>
                    <div className="col-8 col-sm-12 my-1">
                      <div className="progress">
                        <div className="progress-bar bg-main" role="progressbar" style={{ width: "65%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </div>
                    <div className="text-md-start mt-3">
                      <a href="#" className="btn btn_cousre px-5 py-2">₹ 999</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <p className="learm-tilte" style={{ marginTop: "150px" }}>Cart</p>
            
            {/* Cart Item */}
            <div className="spl_cards card mb-3 p-3">
              <div className="row g-0">
                <div className="col-md-4">
                  <img src={CourseImage} alt="Course" className="img-cover w-100 rounded" style={{ height: "200px" }} />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <h4 className="other_course-title">How to Solve Physics Problems</h4>
                    <span className="mb-2">Created by: Alok Jain</span>
                    <div className="course-rating d-flex align-items-center text-end">
                      {[...Array(5)].map((_, index) => (
                        <img key={index} src={StarImage} alt="Star" />
                      ))}
                    </div>
                    <div className="row mt-lg-5">
                      <div className="col-sm-8 text-start"><span>61 hours on demand video</span></div>
                      <div className="col-sm-4 text-end mt-3 mt-lg-0">
                        <a href="#" className="btn btn_cousre px-5 py-2">₹ 999</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
    <Footer/>
    </div>
  );
};

export default StudentProfileCourse;

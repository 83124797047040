import React from 'react';

// Function to convert date string to "Dec 2023"
export const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
        return 'Invalid Date';
    }

    // Options for formatting the date
    const options = { month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options); // Returns "Dec 2023"
};

// Function will give the differnce between in month year
export const calculateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start) || isNaN(end)) {
        return "Invalid date(s) provided";
    }
    // Calculate the difference in years and months
    let yearsDiff = end.getFullYear() - start.getFullYear();
    let monthsDiff = end.getMonth() - start.getMonth();

    // Adjust if necessary
    if (monthsDiff < 0) {
        yearsDiff--; // Reduce the year difference
        monthsDiff += 12; // Adjust to get the correct month difference
    }

    // Format the result
    let result = '';
    if (yearsDiff > 0) {
        result += `${yearsDiff} year${yearsDiff > 1 ? 's' : ''} `;
    }
    if (monthsDiff > 0) {
        result += `${monthsDiff} month${monthsDiff > 1 ? 's' : ''}`;
    }

    // If both yearsDiff and monthsDiff are zero, indicate that the dates are the same
    if (result === '') {
        result = 'The dates are the same.';
    }

    return result.trim();
};

// Function to format the date 2024-10-30 08:52:41 to 15 April 2024 formate convert
export const dateMonthYears = (dateString) => {
    // Parse the date string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date)) {
        return 'Invalid date';
    }

    // Array of month names
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = months[date.getMonth()]; // Get the month name
    const year = date.getFullYear();

    // Return the formatted date
    return `${day} ${month} ${year}`;
};

// Star component to display individual star
const Star = ({ filled }) => {
    return (
      <span style={{ color: filled ? '#FFD700' : '#ccc' }}>
        ★
      </span>
    );
  };
  export const renderStars = (rating) => {
    const numberValue = rating === "NaN" ? 0 : parseFloat(rating);
    const stars = [];
    // Generate filled stars
    for (let i = 0; i < 5; i++) {
      if (i < numberValue) {
        stars.push(<Star key={i} filled={true} />);
      } else {
        stars.push(<Star key={i} filled={false} />);
      }
    }
    return stars;
  }
import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/css/slick.css";
import "../assets/css/line-awesome.min.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";

import DropDownImage from "../assets/images/icons/drop-down-svgrepo-com 1.svg";
import EditIcon from "../assets/images/icons/edit-4-svgrepo-com 1.svg";
import EditActiveIcon from "../assets/images/icons/edit-4-svgrepo-com_active.svg";
import CourseIcon from "../assets/images/icons/course-diary-svgrepo-com.svg";
import CourseActiveIcon from "../assets/images/icons/course-diary-svgrepo-com_active.svg";
import UploadIcon from "../assets/images/icons/upload-square-svgrepo-com 1.svg";
import UploadActiveIcon from "../assets/images/icons/upload-square-svgrepo-com_active.svg";
import AssignmentIcon from "../assets/images/icons/return-order-svgrepo-com 1.svg";
import AssignmentActiveIcon from "../assets/images/icons/return-order-svgrepo-com_active.svg";
import CertificateIcon from "../assets/images/icons/certificate-check-svgrepo-com 1.svg";
import CertificateActiveIcon from "../assets/images/icons/certificate-check-svgrepo-com_active.svg";
import MessageIcon from "../assets/images/icons/email-envelope-letter-mail-message-svgrepo-com 1.svg";
import MessageActiveIcon from "../assets/images/icons/email-envelope-letter-mail_active.svg";
import HistoryIconActive from "../assets/images/icons/history-svgrepo-com_active.svg";
import HistoryIcon from "../assets/images/icons/history-svgrepo-com 1.svg";

const StudentSidebar = () => {
  const navigate = useNavigate();

    return (
        <div className="col-sm-3 dashboard_right d-none d-xl-block">
        <div className="padding_100" onClick={()=>navigate(-1)}>
          <img src={DropDownImage} alt="Back" />
          <span className="ms-2">Back</span>
        </div>
        <div className="dashboard-sidebar">
          <button type="button" className="dashboard-sidebar__close d-lg-none d-flex">
            <i className="las la-times"></i>
          </button>
          <div className="dashboard-sidebar__inner">
            {/* Sidebar List */}
            <ul className="sidebar-list">
              <li className="sidebar-list__item activePage">
                <a href="dashboard.html" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={EditIcon} alt="Edit Profile" className="icon" />
                    <img src={EditActiveIcon} alt="Edit Profile Active" className="icon icon-active" />
                  </span>
                  <span className="text">Edit Profile</span>
                </a>
              </li>
              {/* Add other sidebar items similarly */}
              <li className="sidebar-list__item">
                <a href="/StudentProfileCourse" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={CourseIcon} alt="Courses" className="icon" />
                    <img src={CourseActiveIcon} alt="Courses Active" className="icon icon-active" />
                  </span>
                  <span className="text">Courses</span>
                </a>
              </li>
              <li className="sidebar-list__item">
                <a href="/DashboardPost" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={UploadIcon} alt="Posts" className="icon" />
                    <img src={UploadActiveIcon} alt="Posts Active" className="icon icon-active" />
                  </span>
                  <span className="text">Posts</span>
                </a>
              </li>
              <li className="sidebar-list__item">
                <a href="/StudentOrderAssign" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={AssignmentIcon} alt="Assignments Order" className="icon" />
                    <img src={AssignmentActiveIcon} alt="Assignments Order Active" className="icon icon-active" />
                  </span>
                  <span className="text">Assignments Order</span>
                </a>
              </li>
              <li className="sidebar-list__item">
                <a href="/StudentCertificate" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={CertificateIcon} alt="Certificates" className="icon" />
                    <img src={CertificateActiveIcon} alt="Certificates Active" className="icon icon-active" />
                  </span>
                  <span className="text">Certificates</span>
                </a>
              </li>
              <li className="sidebar-list__item">
                <a href="/DashboardChat" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={MessageIcon} alt="Messages" className="icon" />
                    <img src={MessageActiveIcon} alt="Messages Active" className="icon icon-active" />
                  </span>
                  <span className="text">Messages</span>
                </a>
              </li>
              <li className="sidebar-list__item">
                <a href="/StudentProfileHistory" className="sidebar-list__link">
                  <span className="sidebar-list__icon">
                    <img src={HistoryIcon} alt="History" className="icon" />
                    <img src={HistoryIconActive} alt="History Active" className="icon icon-active" />
                  </span>
                  <span className="text">History</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
};

export default StudentSidebar;

import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images
import DropDownIcon from "../../assets/images/icons/drop-down-svgrepo-com 1.svg";
import EditIcon from "../../assets/images/icons/edit-4-svgrepo-com 1.svg";
import EditImage from "../../assets/images/icons/edit-1-svgrepo-com.svg";
import teacherImage from '../../assets/images/icons/teacehr_+.svg';
import downArrowImage from '../../assets/images/icons/down-arrow-5-svgrepo-com (2) 4.svg'
import UpImage from '../../assets/images/icons/down-arrow-5-svgrepo-com (2) 3.svg'
import EditActiveIcon from "../../assets/images/icons/edit-4-svgrepo-com_active.svg";
import CourseIcon from "../../assets/images/icons/course-diary-svgrepo-com.svg";
import CourseActiveIcon from "../../assets/images/icons/course-diary-svgrepo-com_active.svg";
import UploadIcon from "../../assets/images/icons/upload-square-svgrepo-com 1.svg";
import UploadActiveIcon from "../../assets/images/icons/upload-square-svgrepo-com_active.svg";
import AssignmentIcon from "../../assets/images/icons/return-order-svgrepo-com 1.svg";
import AssignmentActiveIcon from "../../assets/images/icons/return-order-svgrepo-com_active.svg";
import CertificateIcon from "../../assets/images/icons/certificate-check-svgrepo-com 1.svg";
import CertificateActiveIcon from "../../assets/images/icons/certificate-check-svgrepo-com_active.svg";
import MessageIcon from "../../assets/images/icons/email-envelope-letter-mail-message-svgrepo-com 1.svg";
import MessageActiveIcon from "../../assets/images/icons/email-envelope-letter-mail_active.svg";
import NotificationIcon from "../../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import ManageEditIcon from "../../assets/images/icons/manage_edit.svg";
import ProfileImage from "../../assets/images/all/profiel.png";
import SearchIcon from "../../assets/images/all/search.svg";
import HistoryIconActive from "../../assets/images/icons/history-svgrepo-com_active.svg";
import HistoryIcon from "../../assets/images/icons/history-svgrepo-com 1.svg";
import FeedImage from "../../assets/images/all/fedd.png";
import { Footer } from "../../components";

const TeacherChenal = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <div className="col-sm-3 dashboard_right d-none d-xl-block">
            <div className="padding_100" onClick={()=>navigate('/DashboardChat')}>
              <img src={DropDownIcon} alt="Back" />
              <span className="ms-2">Back</span>
            </div>
            <div className="dashboard-sidebar">
              <button type="button" className="dashboard-sidebar__close d-lg-none d-flex">
                <i className="las la-times"></i>
              </button>
              <div className="dashboard-sidebar__inner">
                <ul className="sidebar-list">
                  <li className="sidebar-list__item activePage">
                    <a href="dashboard.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={EditIcon} alt="Edit Profile" className="icon" />
                        <img src={EditActiveIcon} alt="Edit Profile Active" className="icon icon-active" />
                      </span>
                      <span className="text">Edit Profile</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentProfileCourse" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CourseIcon} alt="Courses" className="icon" />
                        <img src={CourseActiveIcon} alt="Courses Active" className="icon icon-active" />
                      </span>
                      <span className="text">Courses</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardPost" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={UploadIcon} alt="Posts" className="icon" />
                        <img src={UploadActiveIcon} alt="Posts Active" className="icon icon-active" />
                      </span>
                      <span className="text">Posts</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentOrderAssign" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={AssignmentIcon} alt="Assignments Order" className="icon" />
                        <img src={AssignmentActiveIcon} alt="Assignments Order Active" className="icon icon-active" />
                      </span>
                      <span className="text">Assignments Order</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentCertificate" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CertificateIcon} alt="Certificates" className="icon" />
                        <img src={CertificateActiveIcon} alt="Certificates Active" className="icon icon-active" />
                      </span>
                      <span className="text">Certificates</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardChat" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={MessageIcon} alt="Messages" className="icon" />
                        <img src={MessageActiveIcon} alt="Messages Active" className="icon icon-active" />
                      </span>
                      <span className="text">Messages</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="dashboard-profile.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={HistoryIcon} alt="History" className="icon" />
                        <img src={HistoryIconActive} alt="History Active" className="icon icon-active" />
                      </span>
                      <span className="text">History</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5 ">
                <div className="row mt-5">
                    <div className="col-md-9 ps-0">
                        <div className="search-box dashboard_search">
                            <input type="text"
                                className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                                placeholder="Search"/>
                            <button type="submit" className="btn btn-main spl_burron  border-0"><img
                                    src={SearchIcon} alt="" /></button>
                        </div>
                    </div>
                    <div className="col-md-2 text-xl-center  mt-3">
                        <img src={NotificationIcon} alt="" />
                    </div>
                    <div className="col-md-1">
                        <div className="dashboard_profile ms-auto">
                            <img src={ProfileImage} alt=""/>
                        </div>
                    </div>
                </div>
                <p className="learm-tilte my-3">Edit Profile</p>

                <div className="row p-0 ">
                    <div className="dashboard_card p-0">

                        <div className="row py-4 px-5">
                            <div className="">
                                <div className="row px-0 px-sm-3">
                                    <div className="col-7 col-sm-8">
                                        <p className="profile_text_2">Personal Info</p>
                                    </div>
                                    <div className="col-sm-4 col-5 text-end">
                                        <button>

                                            <img src={EditImage} alt=""/>
                                            Edit
                                        </button>

                                    </div>
                                </div>
                                <div className="row px-0 px-sm-3">
                                    <div className="col-sm-12 mt-4">
                                    
                                        <label for="courseTitle" className="profile_text_2 mb-3">Course Title</label>
                                        <div className="position-relative">
                                            <input type="text" className="common-input common-input--md dash_borad_input_1"
                                                id="courseTitle"
                                                placeholder="e.g. The Complete AP & College Physics Cou" maxlength="60"/>
                                            <span className="character-count position-absolute"
                                                style={{ right: '10px', top: '50%', transform: 'translateY(-50%)' }}>0/60</span>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-4">
                                       
                                        <label for="courseDescription" className="profile_text_2 mb-3">Course
                                            Description</label>
                                        <div className="position-relative">
                                            <textarea className="common-input common-input--md dash_borad_input_1"
                                                id="courseDescription" style={{ height: '300px' }}
                                                placeholder="Simply dummy text of the printing and typesetting industry..."
                                                maxlength="300"></textarea>
                                            <span className="character-count position-absolute"
                                                style={{ right: '10px', bottom: '10px' }}>200/300</span>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-4">
                                     
                                        <label for="courseCategory" className="profile_text_2 mb-3">Course Category</label>
                                        <div className="position-relative">
                                            <select className="common-input common-input--md dash_borad_input_1"
                                                id="courseCategory">
                                                <option selected disabled>Choose a Category</option>
                                               
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-4">
                                   
                                        <label for="courseDuration" className="profile_text_2 mb-3">Course Duration</label>
                                        <div className="position-relative">
                                            <select className="common-input common-input--md dash_borad_input_1"
                                                id="courseDuration">
                                                <option selected disabled>Choose Time</option>
                                              
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-4">
                                       
                                        <label for="courseRequirements" className="profile_text_2 mb-3">Course
                                            Requirements</label>
                                        <p>List the required skills, experience, tools or equipment learners should have
                                            prior to taking your course. If there are no requirements, use this space as
                                            an opportunity to lower the barrier for beginners.</p>
                                        <div className="position-relative">
                                            <textarea className="common-input common-input--md dash_borad_input_1"
                                                id="courseRequirements" rows="3"
                                                placeholder="e.g. Simply dummy text of the printing and typesetting industry..."></textarea>
                                        </div>

                                      
                                        <button type="button" className="me-2"><img src={teacherImage}
                                                alt=""/></button> <span>Add more outcomes</span>
                                    </div>

                                    <div className="col-sm-12 mt-4">
                                  
                                        <label for="topicsCovered" className="profile_text_2 mb-3">Topics Covered</label>
                                        <div className="position-relative">
                                            <select className="common-input common-input--md dash_borad_input_1"
                                                id="topicsCovered">
                                                <option selected disabled>Select Topics </option>
                                               
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-4 ">
                                     
                                        <label for="courseImage" className="profile_text_2 mb-3">Course Image </label>
                                        <p>Upload your course image here.It must meet our course image quality standards
                                            to be accepted.Important guidelines :750x422
                                            pixels;.jpg,.jpeg.,.gif,or.png.no text on the image.</p>
                                        <div className="height_370">

                                        </div>
                                   
                                        <div className="mt-3 text-end">
                                            <button
                                                className="btn btn_cousre bg-main-btn text-white py-2 px-4 fw-500 font-20">Upload
                                                File</button>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 mt-4 ">
                                       
                                        <label for="promoVideo" className="profile_text_2 mb-3 ">Promotional Video
                                        </label>
                                        <p>Your promo video is a quick and compelling way for students to preview what
                                            they’ll learn in your course.Students considering your course are more
                                            likely to enroll if your promo video is well-made.</p>

                                        <div className="height_370">

                                        </div>

                                     
                                        <div className="mt-3 text-end">
                                            <button
                                                className="btn btn_cousre bg-main-btn text-white py-2 px-4 fw-500 font-20">Upload
                                                File</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div className="row mb-5 mt-3">
                    <div className="col-sm-12 d-flex justify-content-between">
                        <span className="learm-tilte2 font-16"><img src={downArrowImage} alt="" /> Previous</span>
                        <span className="learm-tilte2 font-16">Next <img src={UpImage} alt="" /></span>
                    </div>
                </div>

            </div>

          {/* Main Content Area */}
         
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TeacherChenal;

import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";


import FeedImage from "../../assets/images/all/fedd.png";
import ShareIcon from "../../assets/images/icons/ion_share-outline.svg";
import DownloadIcon from "../../assets/images/icons/octicon_download-16.svg";
import PdfIcon from "../../assets/images/icons/prime_file-pdf.svg";
import { Footer } from "../../components";
import GlobalSearch from "../../components/GlobalSearch";
import StudentSidebar from "../../components/StudentSidebar";

const StudentCertificateDetail = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
        <StudentSidebar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
           <GlobalSearch/>

            <div className="row align-items-center">
              <div className="col-sm-12">
                <p className="learm-tilte my-3">Certificates</p>
              </div>
            </div>

            <div className="row p-0 mb-5">
              {/* Card start */}
              <div className="col-sm-12">
                <div className="dashboard_card p-3" style={{ minHeight: "600px" }}>
                  <div className="feed_imgs" style={{ height: "100%" }}>
                    <img className="rounded-3" src={FeedImage} alt="Certificate" />
                  </div>
                  <span className="course-checkout mt-2">Atomic & Nuclear Particle Physics</span> <br />
                  <span className="dashboard_subitel">Instructor: Alok Jain</span>
                  {/* Action Buttons */}
                  <div className="text-end pb-2 mt-3 text-white">
                    <a href="#" className="btn btn_cousre text-white py-2 pill px-3">
                      <img src={ShareIcon} alt="Share" /> Upload
                    </a>
                    <img className="mx-5" src={DownloadIcon} alt="Download" />
                    <img src={PdfIcon} alt="PDF" />
                  </div>
                </div>
              </div>
              {/* Card end */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentCertificateDetail;

import React,{useState} from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Import images


import feddImage from "../../assets/images/all/fedd.png";
import ProfileImage from "../../assets/images/all/profiel.png";

import { Footer } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import GlobalSearch from "../../components/GlobalSearch";

const StudentChatListRemove = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Media');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const tabStyle = {
    padding: '10px 30px',
    margin: '0 20px',
    fontSize: '16px',
    borderRadius: '20px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    transition: 'transform 0.2s ease',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const activeStyle = {
    background: 'linear-gradient(45deg, #ff8c00, #ffa500)',
    color: 'white',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '200px',
    padding: '30px 0',
    // backgroundColor: '#f5f5f5',
    borderRadius: '15px',
    // margin: '20px auto',
    width: '100%',
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Header */}
      {/* <header className="header">
        <div className="container container-full">
          <nav className="header-inner flx-between">
            <div className="logo"></div>
            <div className="header-right flx-align pe-lg-5">
              <button type="button" className="toggle-mobileMenu d-lg-none">
                <i className="las la-bars"></i>
              </button>
            </div>
          </nav>
        </div>
      </header> */}

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
        <StudentSidebar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5 mb-5 ">
  {/* Search Box */}
  <GlobalSearch/>

  {/* Messages List */}
  <div className="row gy-3 p-0 my-5 mb-3 dashboard_card pb-3">
    <div className="p-0">
      <div className="row justify-content-center p-4">
        <div className="col-sm-12 text-center">
          <div className="chat_icons_group mx-auto position-relative">
            <img src={ProfileImage} alt="Profile" className="rounded-circle" />
            <img src={ProfileImage} alt="Profile" className="rounded-circle group_img" />
          </div>
          <p className="course_video_time font-21 fw-500">The Complete AP and College Physics</p>
          <p className="mt-0 single-line-text">Sent 15m ago</p>
        </div>
      </div>
    </div>

    {/* Individual Message List Items */}
    {[1, 2, 3, 4].map((_, index) => (
      <div key={index} className="col-sm-10 my-4 mx-auto">
        <div className="row align-items-center">
          <div className="col-2 col-sm-1">
            <div className="chat_icons mx-auto position-relative">
              <img src={ProfileImage} alt="Profile" className="rounded-circle" />
              <div className="circle_dot"></div>
            </div>
          </div>
          <div className="col-7 col-sm-9">
            <p className="course_video_time ms-3 fw-500">Sarah Solomon</p>
          </div>
          <div className="col-3 col-sm-2 text-start">
            <span className="mt-0 learm-tilte2 font-13">Remove</span>
          </div>
        </div>
      </div>
    ))}
  </div>

  

<div style={containerStyle}>
      <button
        style={{ ...tabStyle, ...(activeTab === 'Media' ? activeStyle : {}) }}
        onClick={() => handleTabClick('Media')}
      >
        Media
      </button>
      <button
        style={{ ...tabStyle, ...(activeTab === 'Document' ? activeStyle : {}) }}
        onClick={() => handleTabClick('Document')}
      >
        Document
      </button>
      <button
        style={{ ...tabStyle, ...(activeTab === 'Links' ? activeStyle : {}) }}
        onClick={() => handleTabClick('Links')}
      >
        Links
      </button>
    </div>

  {/* Tab Content */}
  {activeTab === "Media" && (
              <div className="row gy-4">
                {[...Array(6)].map((_, index) => (
                  <div key={index} className="col-sm-4 col-md-6 col-lg-6 col-xl-4 mb-4 feed_imgs">
                    <img src={feddImage} alt="Media" />
                  </div>
                ))}
              </div>
            )}

            {activeTab === "Document" && (
              <div className="row gy-4">
                {[...Array(3)].map((_, index) => (
                  <div key={index} className="dashboard_card p-0 mb-3">
                    <div className="row align-items-center px-4 py-4">
                      <div className="col-10">
                        <span className="course_video_time fw-500">Document Title</span>
                        <span className="ms-3">10 MB</span>
                      </div>
                      <div className="col-2 text-end">
                        <span>Sunday</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {activeTab === "Links" && (
              <div className="row gy-4">
                <div className="dashboard_card p-0">
                  <div className="row align-items-center px-4 py-4">
                    <div className="col-10">
                      <a href="https://www.figma.com/design" className="text-main">
                        https://www.figma.com/design
                      </a>
                    </div>
                    <div className="col-2 text-end">
                      <span>Sunday</span>
                    </div>
                  </div>
                </div>
              </div>
            )}
</div>


        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default StudentChatListRemove;

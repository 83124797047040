import React from 'react';
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
const AdminNavbar = () => {
    return (
        <div className="navcontainer">
            <nav className="nav">
                <div className="nav-upper-options">
                    <div className="nav-option option1">
                        {/* <img
                            src="https://media.geeksforgeeks.org/wp-content/uploads/20221210182148/Untitled-design-(29).png"
                            className="nav-img"
                            alt="dashboard"
                        /> */}
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/dashboard">Dashboard </NavLink>
                        </li>
                        {/* <h3></h3> */}
                    </div>

                    <div className="option2 nav-option">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/listRole">Role </NavLink>
                        </li>
                    </div>
                    <div className="option2 nav-option">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin/listCourse">Courses </NavLink>
                        </li>
                    </div>

                    {/* <div className="nav-option logout">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">Logout </NavLink>
                        </li>
                    </div> */}
                </div>
            </nav>
        </div>
    );
};

export default AdminNavbar;
import React, { useEffect, useState } from 'react';
import { Footer, Navbar } from "../components";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import GoogleImage from '../../src/assets/images/all/google.svg';
import FacebookImage from '../../src/assets/images/all/facebook.svg';
import loginImage from '../../src/assets/images/all/login.png';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';

const Register = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [state, setState] = useState({
        userName: '',
        useremail: '',
        userPassword: '',
        usermobile: '',
        position: '',
        successMessage: null
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const validate = () => {
        const newErrors = {};
        if (!state.userName.trim()) newErrors.userName = "Name is required";
        if (!state.useremail) newErrors.useremail = "Email is required";
        else if (!/\S+@\S+\.\S+/.test(state.useremail)) newErrors.useremail = "Email address is invalid";
        if (!state.usermobile) newErrors.usermobile = "Mobile number is required";
        else if (!/^[0-9]{10}$/.test(state.usermobile)) newErrors.usermobile = "Mobile number must be 10 digits";
        if (!state.position) newErrors.position = "Position is required";
        if (!state.userPassword) newErrors.userPassword = "Password is required";
        else if (state.userPassword.length < 6) newErrors.userPassword = "Password must be at least 6 characters";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmitClick = (e) => {
        e.preventDefault();
        if (!validate()) return;

        const payload = {
            "mobile_no": state.usermobile,
            "email": state.useremail,
        };
        setErrorMessage('');
        axios.post(API_BASE_URL + '/send-mobile-verification-request', payload)
            .then(function (response) {
                if (response.data.code == 200) {
                    localStorage.setItem(ACCESS_TOKEN_NAME, response.data.access_token);
                    redirectToRegisterOTP();
                }
                else{
                    setErrorMessage(response.data.message)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    const redirectToRegisterOTP = () => {
        const { userName, useremail, userPassword, usermobile, position } = state;
        navigate("/registerOtp", {
            state: {
                userName,
                useremail,
                userPassword,
                usermobile,
                position,
            },
        });
    };

    useEffect(() => {
        const url = API_BASE_URL + '/getRoleData';
        const fetchRoles = async () => {
            try {
                const response = await axios.get(url);
                setRoles(response.data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchRoles();
    }, []);
    

    return (
        
        <section className="account d-flex flex-wrap" style={{ height: '100vh' }}>
            <div className="account__left position-relative d-md-flex">
                <img 
                    src={loginImage}
                    alt="Login Image"
                    className="account__img position-absolute w-100 h-100 object-cover"
                />
            </div>

            <div className="account__right flx-align d-flex justify-content-center align-items-start align-items-md-center">
                <div className="account-content">
                    <h2 className="login-heading font-heading text-end mb-4">Create an Account</h2>
                    <form onSubmit={handleSubmitClick}>
                        <div className="row gy-2">
                            <div className="col-12">
                                <label htmlFor="userName" className="form-label mb-2 font-18 font-heading fw-600">Name</label>
                                <div className="position-relative">
                                    <input 
                                        type="text" 
                                        className="common-input common-input--bg" 
                                        id="userName" 
                                        placeholder="Name" 
                                        value={state.userName} 
                                        onChange={handleChange}
                                    />
                                    {errors.userName && <span className="error-text">{errors.userName}</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="position" className="form-label mb-2 font-18 font-heading fw-600">Position</label>
                                <div className="position-relative">
                                    <select 
                                        className="common-input common-input--bg" 
                                        id="position" 
                                        value={state.position} 
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Position</option>
                                        {roles.map(option => (
                                            <option key={option.id} value={option.id}>
                                                {option.role_name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.position && <span className="error-text">{errors.position}</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="useremail" className="form-label mb-2 font-18 font-heading fw-600">Email Id</label>
                                <div className="position-relative">
                                    <input 
                                        type="email" 
                                        className="common-input common-input--bg" 
                                        id="useremail" 
                                        placeholder="infoname@mail.com" 
                                        value={state.useremail} 
                                        onChange={handleChange}
                                    />
                                    {errors.useremail && <span className="error-text">{errors.useremail}</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="usermobile" className="form-label mb-2 font-18 font-heading fw-600">Mobile No</label>
                                <div className="position-relative">
                                    <input 
                                        type="text" 
                                        className="common-input common-input--bg" 
                                        id="usermobile" 
                                        placeholder="Mobile No" 
                                        value={state.usermobile} 
                                        onChange={handleChange}
                                    />
                                    {errors.usermobile && <span className="error-text">{errors.usermobile}</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <label htmlFor="userPassword" className="form-label mb-2 font-18 font-heading fw-600">Create Password</label>
                                <div className="position-relative">
                                    <input 
                                        type="password" 
                                        className="common-input common-input--bg" 
                                        id="userPassword" 
                                        placeholder="Password" 
                                        value={state.userPassword} 
                                        onChange={handleChange}
                                    />
                                    {errors.userPassword && <span className="error-text">{errors.userPassword}</span>}
                                </div>
                            </div>
                            {errorMessage && (
                    <span className="error-message">{errorMessage}</span>)}
                            <div className="col-12 mt-4">
                                <button type="submit" className="btn btN_login btn-main btn-lg w-100 pill">Create Account</button>
                            </div>
                            <div className="col-sm-12 mb-0">
                                <div className="have-account">
                                    <p className="text font-14 text-center">Or</p>
                                </div>
                            </div>
                            <div className="col-12">
                                <button type="button" className="btn btn-outline-light btn-lg-icon btn-lg w-100 pill">
                                    <span className="icon icon-left">
                                        <img src={GoogleImage} alt="" />
                                    </span>
                                    Sign in with Google
                                </button>
                            </div>
                            <div className="col-12">
                                <button type="button" className="btn btn-outline-light btn-lg-icon btn-lg w-100 pill">
                                    <span className="icon icon-left">
                                        <img src={FacebookImage} width="20px" alt="" />
                                    </span>
                                    Sign in with Facebook
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Register;



import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';

import StarImage from '../../src/assets/images/all/star-svgrepo-com.svg';
import CourseImage from '../../src/assets/images/course/image-1.png';
import SearchImage from '../../src/assets/images/all/search.svg';
import LogoImage from '../../src/assets/images/all/scan-svgrepo-com 1.svg';
import unionImage from '../../src/assets/images/icons/Union-1.svg';
import SearchIcon from '../../src/assets/images/all/search.svg';

function CourseCart(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [course, setCourse] = useState(""); // State to hold course data
    const [cartItems, setCartItems] = useState([]);
    const [courseDetails, setCourseDetails] = useState([]);
    const [createdBy, setCreatedBy] = useState([]);
    const [courseDetailBasedOnUserid, setCourseDetailBasedOnUserid] = useState([]);
    const [createdById, setCreatedById] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        getCartItems();
    }, []); // Add id as dependency to run effect when id changes
    const getCartItems = async () => {
        const user_id = ACCESS_Log_In_User_Id;
        axios.get(`${API_BASE_URL}/cart?user_id=${user_id}`)
            .then(function (response) {
                // console.log(response.data);
                // console.log(response.data.data);
                //  console.log(response.data.code);
                if (response.data.code == 200) {
                    console.log(response.data);
                    setCartItems(response.data.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });

    };
    useEffect(() => {
        const fetchCourseDetails = async () => {
            setLoading(true);
            setError(null);

            try {
                // Create an array of promises for each product API call
                const promises = cartItems.map(item =>
                    axios.get(API_BASE_URL + '/getCoursesDetailsById/' + item.courses_details_id)
                );

                // Wait for all the promises to resolve
                const responses = await Promise.all(promises);

                // Map the responses to extract product details
                const products = responses.map(response => response.data);
                console.log(products);
                // Set the product details state
                setCourseDetails(products);

            } catch (err) {
                // Handle errors
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseDetails();
    }, [cartItems]); // Run the effect when cartItems changes


    useEffect(() => {
        const fetchCourseDetailBasedOnUserid = async () => {
            
            try {
                // Create an array of promises for each product API call
                const createdBypromisess = courseDetails.map(innerArray => (
                    innerArray.map(courses => (
                    axios.post(API_BASE_URL+'/getCoursesDetailsByUserId',{'user_id':courses.user_id})
                    ))
                ));

                // Wait for all the promises to resolve
                const createdByresponsess = await Promise.all(createdBypromisess);
                console.log("+++++++++++++");
                console.log(createdByresponses);
                console.log("+++++++++++++");
                // Map the responses to extract product details
                
                const createdByproductss = createdByresponsess.map(createdByresponsess => createdByresponsess.data);
                
                console.log(createdByproductss);
                //console.log(PromiseResult);
                console.log("+++++++++++++");
                //const users = createdByproducts.map(createdByproducts => createdByproducts.data);
                
                // Set the product details state
                setCourseDetailBasedOnUserid(createdByproducts);

            } catch (err) {
                // Handle errors
                setError(err.message);
            } finally {
                setLoading(false);
            }
                
        };
        const fetchCreatedBy = async () => {
            setLoading(true);
            setError(null);
            try {
                // Create an array of promises for each product API call
                const createdBypromises = courseDetails.map(innerArray => (
                    innerArray.map(courses => (
                    axios.get(`${API_BASE_URL}/getCoursesCreatedBy?user_id=${courses.user_id}`)
                    ))
                ));

                // Wait for all the promises to resolve
                const createdByresponses = await Promise.all(createdBypromises);
                //console.log(createdByresponses);
                // Map the responses to extract product details
                
                const createdByproducts = createdByresponses.map(createdByresponses => createdByresponses.data);
                // console.log("===============");
                // console.log(createdByproducts);
                // console.log("===============");
                //const users = createdByproducts.map(createdByproducts => createdByproducts.data);
                
                // Set the product details state
                setCreatedBy(createdByproducts);

            } catch (err) {
                // Handle errors
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCourseDetailBasedOnUserid();
        fetchCreatedBy();
    }, [cartItems]); // Run the effect when cartItems changes


    const removeFromCart = async (id) => {
        const user_id = ACCESS_Log_In_User_Id;
        axios.delete(`${API_BASE_URL}/cart/${id}/${user_id}`)
            .then(function (response) {

                if (response.data.code == 200) {
                    //console.log(response.data);
                    getCartItems();
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    // Function to calculate total price
    const calculateTotalPrice = () => {
        return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    };
    const amount = calculateTotalPrice(); // Example amount
    const handleCheckout = () => {
        // Redirect to checkout with the amount as state
        navigate('/CourseCheckout', { state: { amount } });
      };
    return (
        <>
            <div>
                <div className="progress-wrap">
                    <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                    </svg>
                </div>

                {/* Mobile Menu */}
                <div className="mobile-menu d-lg-none d-block">
                    <button type="button" className="close-button">
                        <i className="las la-times"></i>
                    </button>
                    <div className="mobile-menu__inner">
                        <div className="mobile-menu__menu">
                            <ul className="nav-menu flx-align nav-menu--mobile">
                                <li className="nav-menu__item activePage">
                                    <a href="/" className="nav-menu__link">Home</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="course.html" className="nav-menu__link">Courses</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Feed</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Videos</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Teachers</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Institutes</a>
                                </li>
                                <li className="nav-menu__item has-submenu">
                                    <a href="#" className="nav-menu__link">My Profile</a>
                                    <ul className="nav-submenu">
                                        <li className="nav-submenu__item">
                                            <a href="/" className="nav-submenu__link">Edit Profile</a>
                                        </li>
                                        <li className="nav-submenu__item">
                                            <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                                        </li>
                                        <li className="nav-submenu__item">
                                            <a href="/" className="nav-submenu__link">Your Posts</a>
                                        </li>
                                        <li className="nav-submenu__item">
                                            <a href="/" className="nav-submenu__link">Certificates</a>
                                        </li>
                                        <li className="nav-submenu__item">
                                            <a href="/" className="nav-submenu__link">Chats</a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Scan OR Code</a>
                                </li>
                            </ul>
                            <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                                <a href="/" className="btn btn-main pill sign_btn px-sm-4">
                                    Signup/Login
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <main className="change-gradient">
                    {/* <header>
                        <div className="logo"></div>
                   
                        <div className="header-menu d-lg-block d-none">
                            <ul className="nav-menu flx-align">
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Home</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="course.html" className="nav-menu__link">Courses</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Feed</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Learn with us</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Teachers</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">Institute</a>
                                </li>
                                <li className="nav-menu__item">
                                    <a href="/" className="nav-menu__link">About Us</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div className="header-right flx-align pe-lg-5">
                            <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
                                <a href="/" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</a>
                                <a href="/" className="btn cicrle_btn btn-main">
                                    <img src={LogoImage} alt="Logo" />
                                </a>
                            </div>
                            <button type="button" className="toggle-mobileMenu d-lg-none">
                                <i className="las la-bars"></i>
                            </button>
                        </div>
                    </header> */}
                    <Navbar/>

                    <section className="hero section-bg z-index-1">
                        <div className="container py-5 container-two">
                            <div className="row align-items-center gy-sm-5 gy-4">
                                <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                                    <div className="position-relative">
                                        <div className="search-box">
                                            <input
                                                type="text"
                                                className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                                                placeholder="Search"
                                            />
                                            <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                                <img src={SearchImage} alt="Search" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Course Details Section */}

                    <section className="pb-5">
                        <div className="container cours_deataisl">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-sm-10 col-md-12">
                                    <div className="row my-4">
                                        {courseDetails.map((innerArray, i) => (
                                            innerArray.map(course => (
                                                <div className="col-lg-9 col-xl-8 order-0 order-md-1">
                                                    <div className="spl_cards card mb-3 p-3">
                                                        <div className="row g-0">
                                                            <div className="col-md-4">
                                                                <img src={`${Image_Base_Url}${course.course_image_path}`} alt="How to Solve Physics Problems" className="img-cover w-100 h-100 rounded-start" />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-8 col-sm-9">
                                                                            <h4 className="other_course-title">{course.course_title}</h4>
                                                                        </div>
                                                                        <div className="col-4 col-sm-3 text-end">
                                                                            <span className="course-time"> ₹{course.course_price}</span>
                                                                        </div>
                                                                        <div className="col-sm-12">
                                                                            <span className="course-author mb-2">Created by: Alok Jain</span>
                                                                            <div className="course-rating d-flex align-items-center text-end">
                                                                                {[...Array(5)].map((_, index) => (
                                                                                    <img key={index} src={StarImage} alt="5 stars" className="Stars me-1" />
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-12 mt-4">
                                                                            <p>{course.course_duration} hours on demand video</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button onClick={() => removeFromCart(course.id)}>Remove</button>
                                                </div>
                                            ))
                                        ))}

                                        <div className="col-lg-3 offset-xl-1 col-xl-3 order-0 order-md-1 p-4 p-sm-0">
                                            <div className="card card_add mb-3 p-md-2 bg-transparent">
                                                <p className="cousrse_p">Total</p>
                                                <span className="course-orgian course-titles" style={{ fontSize: "18px" }}>₹{calculateTotalPrice().toFixed(2)}</span>
                                                <div className="card-body p-0">
                                                    <div className="d-flex align-items-center class_span">
                                                        <span className="course-stricke text-decoration-line-through">₹499</span>
                                                        <span className="course-persantace">40% off</span>
                                                    </div>
                                                    <div className="text-md-center mt-3">
                                                        {/* <a href="#" className="btn btn_cousre w-100">Check Out</a> */}
                                                        <button className="btn btn_cousre w-100" onClick={handleCheckout}>Check Out</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* Other Courses */}
                                    <div className="row my-5">
                                        <h4 className="course-title course-titles mb-4" style={{ marginTop: "150px" }}>Other Courses by Alok Jain</h4>
                                        {[1, 2, 3].map((_, index) => (
                                            <div className="col-sm-4 mb-3" key={index}>
                                                <div className="card spl_cards p-0">
                                                    <div className="card-body">
                                                        <img src={CourseImage} alt="How to Solve Physics Problems" className="img-cover w-100 h-100 rounded-start" />
                                                        <h4 className="other_course-title mt-3">How to Solve Physics Problems</h4>
                                                        <div className="row mb-4">
                                                            <p className="course-time mb-4">30.5 Hours</p>
                                                            <p className="course-description text-muted">
                                                                Simply dummy text of the printing and typesetting industry. Lorem
                                                                Ipsum has been the industry's standard dummy text.
                                                            </p>
                                                        </div>
                                                        <div className="text-md-end">
                                                            <a href="#" className="btn btn_cousre w-100">₹ 999</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                {/* Footer */}

                <Footer />
                {/* <footer className="footer-section">
                <img className="shape_top_10 d-lg-block d-none" src={unionImage} alt="Decoration Shape" />
                <div className="container container-two">
                    <div className="row gy-sm-5">
                        <div className="col-xl-6 col-sm-6">
                            <div className="position-relative mb-5">
                                <div className="search-box">
                                    <input 
                                        type="text" 
                                        className="common-input common-input--lg pill shadow-sm auto-suggestion-input" 
                                        placeholder="Search" 
                                    />
                                    <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                        <img src={SearchIcon} alt="Search Icon" />
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-5 col-xs-6">
                                    <div className="footer-widget">
                                        <ul className="footer-lists">
                                            <li className="footer-lists__item">
                                                <a href="/" className="footer-lists__link">Home</a>
                                            </li>
                                            <li className="footer-lists__item">
                                                <a href="/courses" className="footer-lists__link">Courses</a>
                                            </li>
                                            <li className="footer-lists__item">
                                                <a href="/feed" className="footer-lists__link">Feed</a>
                                            </li>
                                            <li className="footer-lists__item">
                                                <a href="/teachers" className="footer-lists__link">Teachers</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-xs-6 ps-xl-5">
                                    <div className="footer-widget">
                                        <ul className="footer-lists">
                                            <li className="footer-lists__item">
                                                <a href="/about-us" className="footer-lists__link">About Us</a>
                                            </li>
                                            <li className="footer-lists__item">
                                                <a href="/payment" className="footer-lists__link">Payment</a>
                                            </li>
                                            <li className="footer-lists__item">
                                                <a href="/profile" className="footer-lists__link">My Profile</a>
                                            </li>
                                            <li className="footer-lists__item">
                                                <a href="/institutes" className="footer-lists__link">Institutes</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="footer-widget mt-4">
                                    <h3>Follow us on</h3>
                                    <div className="footer-widget__social">
                                        <ul className="social-icon-list">
                                            <li className="social-icon-list__item">
                                                <a href="https://www.facebook.com" className="social-icon-list__link flx-center">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </li>
                                            <li className="social-icon-list__item">
                                                <a href="https://www.youtube.com" className="social-icon-list__link flx-center">
                                                    <i className="fab fa-youtube"></i>
                                                </a>
                                            </li>
                                            <li className="social-icon-list__item">
                                                <a href="https://www.linkedin.com" className="social-icon-list__link flx-center">
                                                    <i className="fab fa-linkedin-in"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 offset-xl-1 col-sm-6">
                            <div className="footer-widget">
                                <div className="map-box mb-30 mt-5">
                                    <li className="footer-lists__item">
                                        <a href="/" className="footer-lists__link">Location</a>
                                    </li>
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                                        width="100%"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
            </div>
        </>
    );
}

export default CourseCart;

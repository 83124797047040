

import React from 'react';
import NotificationIcon from '../assets/images/icons/notification-bing-svgrepo-com 1.svg';
import ProfileImage from '../assets/images/all/profiel.png';
import SearchImage from '../assets/images/all/search.svg';

const GlobalSearch = ({ profileImage }) => {
   
    const displayImage = profileImage || ProfileImage;

    return (
        <div className="row mt-5">
            {/* Search Box Section */}
            <div className="col-md-9 ps-0">
                <div className="search-box dashboard_search">
                    <input
                        type="text"
                        className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                        placeholder="Search"
                    />
                    <button type="submit" className="btn btn-main spl_burron border-0">
                        <img src={SearchImage} alt="Search" />
                    </button>
                </div>
            </div>

            {/* Notification Section */}
            <div className="col-md-2 text-xl-center mt-3">
                <img src={NotificationIcon} alt="Notification" />
            </div>

            {/* Profile Section */}
            <div className="col-md-1">
                <div className="dashboard_profile ms-auto">
                    <img src={displayImage} alt="Profile" className="profile-image" />
                </div>
            </div>
        </div>
    );
};

export default GlobalSearch;


// src/redux/slices/authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,ACCESS_Role_ID } from '../../constants/apiConstants';

export const loginUser = createAsyncThunk('auth/loginUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/login`, payload);
    console.log(response.data.data.roleId);
    if (response.data.code === 200) {
      localStorage.setItem('ACCESS_Role_ID', response.data.data.roleId);
      localStorage.setItem('ACCESS_Log_In_User_Id', response.data.data.id);
      localStorage.setItem('ACCESS_TOKEN_NAME', response.data.access_token);
      return response.data;
    } else if (response.data.code === 401) {
      return rejectWithValue(response.data.message || 'Unauthorized');
    } else {
      return rejectWithValue('Username does not exist');
    }
  } catch (error) {
    return rejectWithValue(error.response?.data?.message || 'Login failed');
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    loading: false,
    error: null,
    successMessage: '',
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.successMessage = 'Login successful. Redirecting to home page...';
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError } = authSlice.actions;

export default authSlice.reducer;

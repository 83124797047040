import React, {useState} from 'react';
import { Link,useNavigate } from "react-router-dom";
import { AdminFooter, AdminNavbar } from "../admin";
import axios from 'axios';
import {API_BASE_URL, ACCESS_TOKEN_NAME} from '../../constants/apiConstants';

const AdminLogin = (props) => {
  const navigate = useNavigate();
  const [state , setState] = useState({
    email : "",
    password : "",
    successMessage: null
})
const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
}

const handleSubmitClick = (e) => {
    e.preventDefault();
    const payload={
        "email":state.email,
        "password":state.password,
    }
    axios.post(API_BASE_URL+'/login', payload)
        .then(function (response) {
         // console.log(response.data.access_token);
            if(response.status === 200){
                setState(prevState => ({
                    ...prevState,
                    'successMessage' : 'Login successful. Redirecting to home page..'
                }))
                
                localStorage.setItem(ACCESS_TOKEN_NAME,response.data.access_token);
                console.log(ACCESS_TOKEN_NAME);
                redirectToHome();
                props.showError(null)
            }
            else if(response.code === 204){
                props.showError("Username and password do not match");
            }
            else{
                props.showError("Username does not exists");
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}
const redirectToHome = () => {
    navigate("/admin/dashboard");
}
const redirectToRegister = () => {
    props.history.push('/register'); 
    props.updateTitle('Register');
}
  return (
    <>
      <div className="container my-3 py-3">
        <h1 className="text-center">Login</h1>
        <hr />
        <div className="row my-4 h-100">
          <div className="col-md-4 col-lg-4 col-sm-8 mx-auto">
            <form>
              <div className="my-3">
                <label htmlFor="display-4">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com" value={state.email} onChange={handleChange}
                />
              </div>
              <div className="my-3">
                <label htmlFor="floatingPassword display-4">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  value={state.password}
                       onChange={handleChange} 
                />
              </div>
              <div className="text-center">
                  
                <button 
                    type="submit" 
                    className="btn btn-primary"
                    onClick={handleSubmitClick}
                >Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;

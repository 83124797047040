import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "../pages/Utils";


const LogOut = () => {
    const navigate = useNavigate();
    const handleLogOut = () => {
    localStorage.setItem('ACCESS_TOKEN_NAME', "");
    localStorage.removeItem('ACCESS_TOKEN_NAME'); // Remove token from local storage
    localStorage.setItem('ACCESS_Role_ID', "");
    localStorage.removeItem('ACCESS_Role_ID');
    navigate("/"); 
};
// Make sure to call handleLogOut at the right place
React.useEffect(() => {
    handleLogOut();
}, []); // Only runs once when component mounts

return null; // This component doesn't render anything
};

export default LogOut;

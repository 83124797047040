import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/css/slick.css";
import "../assets/css/line-awesome.min.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";

// Import images

import DropDownIcon from "../assets/images/icons/drop-down-svgrepo-com 1.svg";
import EditIcon from "../assets/images/icons/edit-4-svgrepo-com 1.svg";
import EditActiveIcon from "../assets/images/icons/edit-4-svgrepo-com_active.svg";
import CourseIcon from "../assets/images/icons/course-diary-svgrepo-com.svg";
import CourseActiveIcon from "../assets/images/icons/course-diary-svgrepo-com_active.svg";
import UploadIcon from "../assets/images/icons/upload-square-svgrepo-com 1.svg";
import UploadActiveIcon from "../assets/images/icons/upload-square-svgrepo-com_active.svg";
import AssignmentIcon from "../assets/images/icons/return-order-svgrepo-com 1.svg";
import AssignmentActiveIcon from "../assets/images/icons/return-order-svgrepo-com_active.svg";
import CertificateIcon from "../assets/images/icons/certificate-check-svgrepo-com 1.svg";
import CertificateActiveIcon from "../assets/images/icons/certificate-check-svgrepo-com_active.svg";
import MessageIcon from "../assets/images/icons/email-envelope-letter-mail-message-svgrepo-com 1.svg";
import MessageActiveIcon from "../assets/images/icons/email-envelope-letter-mail_active.svg";
import NotificationIcon from "../assets/images/icons/notification-bing-svgrepo-com 1.svg";
import ManageEditIcon from "../assets/images/icons/manage_edit.svg";
import ProfileImage from "../assets/images/all/profiel.png";
import SearchIcon from "../assets/images/all/search.svg";
import HistoryIconActive from "../assets/images/icons/history-svgrepo-com_active.svg";
import HistoryIcon from "../assets/images/icons/history-svgrepo-com 1.svg";
import { Footer } from "../components";

const DashboardChat = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Edit Profile</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Enrolled Courses</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Your Posts</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Certificates</a>
                  </li>
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">Chats</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Header */}
      {/* <header className="header">
        <div className="container container-full">
          <nav className="header-inner flx-between">
            <div className="logo"></div>
            <div className="header-right flx-align pe-lg-5">
              <button type="button" className="toggle-mobileMenu d-lg-none">
                <i className="las la-bars"></i>
              </button>
            </div>
          </nav>
        </div>
      </header> */}

      {/* Main Content */}
      <div className="container">
        <div className="row">
          {/* Sidebar */}
          <div className="col-sm-3 dashboard_right d-none d-xl-block">
            <div className="padding_100" onClick={()=>navigate('/DashboardChat')}>
              <img src={DropDownIcon} alt="Back" />
              <span className="ms-2">Back</span>
            </div>
            <div className="dashboard-sidebar">
              <button type="button" className="dashboard-sidebar__close d-lg-none d-flex">
                <i className="las la-times"></i>
              </button>
              <div className="dashboard-sidebar__inner">
                <ul className="sidebar-list">
                  <li className="sidebar-list__item activePage">
                    <a href="dashboard.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={EditIcon} alt="Edit Profile" className="icon" />
                        <img src={EditActiveIcon} alt="Edit Profile Active" className="icon icon-active" />
                      </span>
                      <span className="text">Edit Profile</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentProfileCourse" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CourseIcon} alt="Courses" className="icon" />
                        <img src={CourseActiveIcon} alt="Courses Active" className="icon icon-active" />
                      </span>
                      <span className="text">Courses</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardPost" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={UploadIcon} alt="Posts" className="icon" />
                        <img src={UploadActiveIcon} alt="Posts Active" className="icon icon-active" />
                      </span>
                      <span className="text">Posts</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentOrderAssign" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={AssignmentIcon} alt="Assignments Order" className="icon" />
                        <img src={AssignmentActiveIcon} alt="Assignments Order Active" className="icon icon-active" />
                      </span>
                      <span className="text">Assignments Order</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/StudentCertificate" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={CertificateIcon} alt="Certificates" className="icon" />
                        <img src={CertificateActiveIcon} alt="Certificates Active" className="icon icon-active" />
                      </span>
                      <span className="text">Certificates</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="/DashboardChat" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={MessageIcon} alt="Messages" className="icon" />
                        <img src={MessageActiveIcon} alt="Messages Active" className="icon icon-active" />
                      </span>
                      <span className="text">Messages</span>
                    </a>
                  </li>
                  <li className="sidebar-list__item">
                    <a href="dashboard-profile.html" className="sidebar-list__link">
                      <span className="sidebar-list__icon">
                        <img src={HistoryIcon} alt="History" className="icon" />
                        <img src={HistoryIconActive} alt="History Active" className="icon icon-active" />
                      </span>
                      <span className="text">History</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
            <div className="row mt-5">
              <div className="col-md-12 ps-0">
                <div className="search-box dashboard_search">
                  <input
                    type="text"
                    className="common-input common-input--lg dashboard_search_input pill shadow-sm auto-suggestion-input"
                    placeholder="Search"
                  />
                  <button type="submit" className="btn btn-main spl_burron border-0">
                    <img src={SearchIcon} alt="Search" />
                  </button>
                </div>
              </div>
            </div>

            {/* Additional Content */}
            <div className="row align-items-center my-3">
              <div className="col-7 d-flex">
                <div className="text-md-start me-3">
                  <a href="/" className="btn btn_cousre px-2 py-2">General</a>
                </div>
                <div className="text-md-start">
                  <a href="/" className="btn btn_cousre px-2 py-2" style={{ background: "#DDD" }}>Group</a>
                </div>
              </div>
              <div className="col-5 text-end">
                <span className="learm-tilte2 font-14 font-lg-16 me-0">Requests (6)</span>
                <img src={ManageEditIcon} alt="Manage Edit" />
              </div>
            </div>

            {/* Example Cards */}
            <div className="row gy-3 p-0 mb-5">
              {[1, 2, 3, 4].map((item) => (
                <div key={item} className="dashboard_card p-0">
                  <div className="row align-items-end p-4" onClick={()=>navigate('/DashboardChatBox')}>
                    <div className="col-2 col-sm-1">
                      <div className="chat_icons mx-auto position-relative">
                        <img src={ProfileImage} alt="Profile" className="rounded-circle" />
                        <div className="circle_dot"></div>
                      </div>
                    </div>
                    <div className="col-8 col-sm-10">
                      <p className="course_video_time fw-500">Sarah Solomon</p>
                      <p className="mt-1 single-line-text">
                        At least 800x800 px recommended. JPG or PNG is allowed
                      </p>
                    </div>
                    <div className="col-2 col-sm-1 text-end">
                      <span className="mt-2 text-end">10 m</span>
                    </div>
                  </div>
                </div>
              ))}
              <span className="learm-tilte2 font-16 text-center">View More</span>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default DashboardChat;

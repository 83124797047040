//import './App.css';
import React, { useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME } from '../../constants/apiConstants';

function CreateVideo() {

  const [name, setName] = useState("");
  const [teacher_id, setTeacherID] = useState("");
  const [user_id, setUserId] = useState("");
  const [file, setFile] = useState();
  const [teachers_video_detail, setVideoDetail] = useState("");

  function handleChange(event) {

    //setFile(event.target.files[0])
    setName(event.target.name)
    setTeacherID(event.target.teacher_id)
    setUserId(event.target.user_id)
    setVideoDetail(event.target.teachers_video_detail)

  }

  function handleChangeFile(event) {

    setFile(event.target.files[0])
    // setName(event.target.name)
    // setTeacherID(event.target.teacher_id)
    // setUserId(event.target.user_id)
    // setVideoDetail(event.target.teachers_video_detail)

  }

  function handleSubmit(event) {
    event.preventDefault()
    const url = API_BASE_URL + '/createTeachersVideo';
    const formData = new FormData();
    formData.append("name", name);
    formData.append("teacher_id", teacher_id);
    formData.append("user_id", user_id);
    formData.append('teachers_video_name', file);
    formData.append('teachers_video_detail', teachers_video_detail);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
    });

  }

  return (
    <>
      <div className="App">
        <form onSubmit={handleSubmit}>
          <h1>Teacher Video Upload</h1>
          <div className="my-3">
            <label htmlFor="display-4">Video Name</label>
            <input type="text" value={name} onChange={(event) => setName(event.target.value)} />
          </div>
          <div className="my-3">
            <label htmlFor="display-4">Teacher Id</label>
            <input type="number" value={teacher_id} onChange={(event) => setTeacherID(event.target.value)} />
          </div>
          <div className="my-3">
            <label htmlFor="display-4">User Id</label>
            <input type="number" value={user_id} onChange={(event) => setUserId(event.target.value)} />
          </div>
          <div className="my-3">
            <label htmlFor="display-4">Upload Video</label>
            {/* <input type="file" value={file} onChange={(event) => setFile(event.target.value)} /> */}
            <input type="file" onChange={handleChangeFile} />
          </div>
          <div className="my-3">
            <label htmlFor="display-4">Video Description</label>
            <input type="text" value={teachers_video_detail} onChange={(event) => setVideoDetail(event.target.value)} />
          </div>
          <button type="submit">Upload</button>
        </form>
      </div>
    </>
  );
}

export default CreateVideo;
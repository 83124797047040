import React from "react";
import search from '../../src/assets/images/all/search.svg';


const Footer = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="container container-two">
          <div className="row gy-5">
            <div className="col-xl-6 col-sm-6">
              <div className="position-relative mb-5">
                <div className="search-box">
                  <input
                    type="text"
                    className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                    placeholder="Search"
                  />
                  <button
                    type="submit"
                    className="btn btn-main spl_button btn-icon icon border-0"
                    style={{ padding: '0.5rem' }} // Optional padding adjustment
                  >
                    <img
                      src={search}
                      alt="Search"
                      style={{
                        width: '30px', // Increase the width of the icon
                        height: '30px', // Increase the height of the icon
                      }}
                    />
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-xs-6">
                  <div className="footer-widget">
                    <ul className="footer-lists">
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">Home</a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">Courses</a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">Feed</a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">Teachers</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-xs-6 ps-xl-5">
                  <div className="footer-widget">
                    <ul className="footer-lists">
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">About Us</a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">Payment</a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">My Profile</a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="#" className="footer-lists__link">Institutes</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footer-widget mt-4">
                  <h3>Follow us on</h3>
                  <div className="footer-widget__social">
                    <ul className="social-icon-list">
                      <li className="social-icon-list__item">
                        <a href="https://www.facebook.com" className="social-icon-list__link flx-center">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="social-icon-list__item">
                        <a href="https://www.youtube.com" className="social-icon-list__link flx-center">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li className="social-icon-list__item">
                        <a href="https://www.linkedin.com" className="social-icon-list__link flx-center">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-5 offset-xl-1 col-sm-6">
              <div className="footer-widget">
                <div className="map-box mb-30 mt-5">
                  <li className="footer-lists__item">
                    <a href="#" className="footer-lists__link">Location</a>
                  </li>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                    width="100%"
                    height="300px"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "./Utils";
import '../assets/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';

import VectorImage from '../../src/assets/images/icons/Vector.svg'
import IconImage from '../../src/assets/images/icons/pen-swirl-svgrepo-com 1.svg'
import NoteBookImage from '../../src/assets/images/icons/notebook-svgrepo-com 1.svg'
import DownloadImage from '../../src/assets/images/icons/downloads-svgrepo-com 1.svg'
import CertificateImage from '../../src/assets/images/icons/certificate-star-svgrepo-com 1.svg'

import { debounce } from 'lodash'; // Optional for debouncing

function CourseDetail(props) {

    const { id } = useParams();
    console.log(id);
    const [ids, setIds] = useState(id);
    console.log(ids);
    const navigate = useNavigate();
    const [createdBy, setCreatedBy] = useState([]);
    const [courseDetailBasedOnUserid, setCourseDetailBasedOnUserid] = useState([]);
    const [rating, setRating] = useState(0);
    const [courseDetails, setCourseDetails] = useState(null);
    const [averageRatings, setAverageRatings] = useState([]);
    const [course, setCourse] = useState(""); // State to hold roles data
    // Search Start
    const [query, setQuery] = useState('');
    const [searchTeachers, setSearchTeachers] = useState([]);
    const [searchCourses, setSearchCourses] = useState([]);
    const [searchInstitutes, setSearchInstitutes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    
    // Handle search input change
    const handleChange = (e) => {
        setQuery(e.target.value);
    };

    // Debounced search function to limit API calls
    const handleSearch = debounce(async () => {
        if (!query.trim()) return;
        setLoading(true);
        setError('');
        try {
            const response = await axios.get(API_BASE_URL + `/courseSearch?query=${query}`);
            console.log(response.data.data);
            // setTeachers(response.data.teachers);
            setSearchCourses(response.data.data);
            // setInstitutes(response.data.institutes);
        } catch (err) {
            setError('An error occurred while searching.');
        } finally {
            setLoading(false);
        }
    }, 500); // Debounce delay of 500ms

    // Trigger search on key press (after debouncing)
    useEffect(() => {
        handleSearch();
    }, [query]); // Trigger handleSearch when the query changes
    // Handle course click to navigate to the detail page
    const handleCourseClick = (courseId) => {
        setCourseDetails(courseId);
        navigate(`/CourseDetail/${courseId}`); // Navigate to the course detail page
    };
    // Effect to fetch the course details when courseId changes
    useEffect(() => {
        fetchCoursesDetails(id); // Fetch new details every time the courseId changes
    }, [id]);  // Dependency array ensures the effect runs when courseId changes
    // Search End
    const handleAddToCart = async () => {
        const cartItem = {
            courses_details_id: course.id,
            price: course.course_price,
            quantity: 1, // This can be dynamic based on user input
            user_id: ACCESS_Log_In_User_Id,
        };

        try {
            await addToCart(cartItem);
            navigate('/coursecart');
            //alert('Product added to cart!');

        } catch (error) {
            console.error('Error adding to cart:', error);
            alert('Failed to add product to cart');
        }
    };
    const addToCart = async (cartItem) => {
        const response = await axios.post(`${API_BASE_URL}/cart/add`, cartItem, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust for your auth strategy
            },
        });
        return response.data;
    };

    const fetchCoursesDetails = async (ids) => {
        try {
            setLoading(true);
            const url = API_BASE_URL + '/getCoursesDetailsById/' + ids
            const response = await axios.get(url);
            setCourse(response.data[0]); // Set roles data to state
        } catch (err) {
            // setError(err.message); // Handle error

        }
        setLoading(false);
    };
    const fetchCourseDetailBasedOnUserid = async () => {
        const payload = {
            "user_id": course.user_id,
        }
        try {
            axios.post(API_BASE_URL + '/getCoursesDetailsByUserId', payload)
                .then(function (response) {
                    if (response.data.code == 200) {
                        setCourseDetailBasedOnUserid(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (err) {
            // Handle errors
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    const fetchCreatedBy = async () => {
        try {
            axios.get(`${API_BASE_URL}/getCoursesCreatedBy?user_id=${course.user_id}`)
                .then(function (response) {

                    if (response.data.code == 200) {
                        setCreatedBy(response.data.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } catch (err) {
            // Handle errors
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchCreatedBy();
        fetchCourseDetailBasedOnUserid();
        fetchCoursesDetails(ids); // Call the fetch function
    }, []); // Empty dependency array to run only on mount

    // Rating start
    const fetchRatings = async () => {
        setLoading(true);
        try {
            const url = API_BASE_URL + '/ratings/course/' + ids
            const response = await axios.get(url);
            const courserating = response.data.data;
            const averageRatingsss = calculateAverageRating(courserating);
            setAverageRatings(averageRatingsss);
            renderStars(averageRatingsss)
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchRatings();
    }, [course]);

    const handleRating = async (star, courseDetail_id) => {
        setRating(star);
        setcourseDetailId(courseDetail_id);
        await axios.post(API_BASE_URL + '/ratings', {
            user_id: ACCESS_Log_In_User_Id, // Replace with actual user id
            courseDetail_id: courseDetail_id,
            rating: star,
        });
        fetchRatings(); // Refresh ratings
    };
    const calculateAverageRating = (ratingsArray) => {
        const totalRating = ratingsArray.reduce((sum, ratingObj) => sum + ratingObj.rating, 0);
        return (totalRating / ratingsArray.length).toFixed(2); // Return average rounded to 2 decimal places

    };

    // Rating end
    return (
        <div>
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            {/* ==================== Scroll to Top End Here ==================== */}

            {/* ==================== Mobile Menu Start Here ==================== */}
            <div className="mobile-menu d-lg-none d-block">
                <button type="button" className="close-button"> <i className="las la-times"></i> </button>
                <div className="mobile-menu__inner">
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flx-align nav-menu--mobile">
                            <li className="nav-menu__item activePage">
                                <a href="" className="nav-menu__link">Home</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="course.html" className="nav-menu__link">Courses</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Feed</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Videos</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Teachers</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Institutes</a>
                            </li>
                            <li className="nav-menu__item has-submenu">
                                <a href="javascript:void(0)" className="nav-menu__link">My Profile</a>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item">
                                        <a href="" className="nav-submenu__link"> Edit Profile</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="" className="nav-submenu__link"> Enrolled Courses</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="" className="nav-submenu__link"> Your Posts</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="" className="nav-submenu__link"> Certificates</a>
                                    </li>
                                    <li className="nav-submenu__item">
                                        <a href="" className="nav-submenu__link"> Chats</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-menu__item">
                                <a href="" className="nav-menu__link">Scan OR Code</a>
                            </li>
                        </ul>
                        <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                            <a href="" className="btn btn-main pill sign_btn px-sm-4">
                                Signup/Login
                            </a>
                        </div>
                    </div>
                </div>
            </div>


            <main className="change-gradient">

                <Navbar/>

             
                <section className="hero section-bg z-index-1">
                    <div className="container py-5 container-two">
                        <div className="row align-items-center gy-sm-5 gy-4">
                            <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                                <div className="position-relative">
                                    <div className="search-box">
                                        {/* <input
                                            type="text"
                                            className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                                            placeholder="Search"
                                        />
                                        <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                            <img src={SearchImage} alt="Search" />
                                        </button> */}
                                        <input
                                            type="text"
                                            value={query}
                                            onChange={handleChange}
                                            placeholder="Search for courses"
                                            className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                                        />

                                        {loading && <p>Loading...</p>}
                                        {error && <p style={{ color: 'red' }}>{error}</p>}

                                        {searchCourses.length > 0 ? (
                                            <ul>
                                                {searchCourses.map((course) => (
                                                    <li key={course.id} onClick={() => handleCourseClick(course.id)}>
                                                        {course.course_title} - {course.course_description}
                                                    </li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p>No courses found</p>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-5">
                    <div className="container cours_deataisl">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-sm-10 col-md-12">
                                <div className="course-details">
                                    <div className="position-relative cousrse_img_height">
                                        <img
                                            src={`${Image_Base_Url}${course.course_image_path}`}
                                            alt="How to Solve Physics Problems"
                                            className="img-fluid rounded w-100 h-100"
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </div>
                                    <h4 className="course-titles my-4">{course.course_title}</h4>
                                    <p className="course-description text-muted">
                                        {course.course_description}
                                    </p>
                                </div>

                                <div className="row my-4">
                                    <div className="col-sm-6 col-md-6 col-lg-7 col-xl-8 order-1 order-md-0">
                                        <div className="course-rating d-flex align-items-center text-end">
                                            {/* {[...Array(5)].map((_, index) => (
                                                <span className="Stars me-1" title="5 Stars" key={index}>
                                                    <img src={StarImage} alt="5 stars" />
                                                </span>
                                            ))} */}
                                            {ACCESS_TOKEN_NAME ? (
                                                [...Array(5)].map((_, index) => (
                                                    //i === index ? ( // Use a ternary operator to conditionally render
                                                    <span
                                                        key={index + 1}
                                                        onClick={() => handleRating(index + 1, course.id)} // Function to handle user click
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: index + 1 <= rating ? '#FFD700' : '#ccc', // Filled star if rating is equal or higher
                                                            fontSize: '24px',
                                                        }}
                                                    >
                                                        ★
                                                    </span>
                                                    // ) : null // Return null if the condition is not met
                                                ))
                                            ) : (

                                                (() => {
                                                    const numberValue = averageRatings === "NaN" ? NaN : parseFloat(averageRatings);
                                                    return (
                                                        <div>
                                                            {isNaN(numberValue) ? renderStars(0) : renderStars(numberValue)}
                                                        </div>
                                                    );
                                                })()

                                            )}
                                        </div>
                                        {createdBy.map((innerArray, i) => (
                                            <p className="course-author fw-semibold my-3">Created by: {innerArray.name}</p>
                                        ))}
                                        <div className="col-sm-12 d-flex flex-column flex-sm-row">
                                            <p className="course-upload me-sm-5">Uploaded: {dateMonthYears(course.updatedAt)}</p>
                                            <p className="course-language ms-sm-5 text-md-end">Available in: {course.select_language}</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-5 col-xl-4 order-0 order-md-1">
                                        <div className="card card_add mb-3 p-md-2">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center class_span">
                                                    <span className="course-orgian course-titles">₹{course.course_price}</span>
                                                    <span className="course-stricke text-decoration-line-through">₹{course.course_discount_price}</span>
                                                    <span className="course-persantace">{course.course_discount_percent}% off</span>
                                                </div>
                                                <p className="my-md-2 cousrse_p">10 Days Money Back Guarantee Full time access</p>
                                                <div className="text-md-center mt-3">
                                                    {/* <a href="#" className="btn btn_cousre w-100">Add to cart</a> */}
                                                    <button className="btn btn_cousre w-100" onClick={handleAddToCart}>Add to Cart</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Topics Covered */}
                                <div className="row my-5">
                                    <h4 className="course-title course-titles mb-3">Topics Covered</h4>
                                    <a href="blog.html" class="btn btn-outline-light spl_buttonss btn-lg  pill">Browse All
                                        Articles</a>
                                    <a href="blog.html" class="btn btn-outline-light spl_buttonss btn-lg  pill">Browse All
                                        Articles</a>
                                </div>



                                <div className="row my-5">
                                    <h4 className="course-title course-titles mb-3">This course includes</h4>
                                    <div className="col-6 col-sm-4">
                                        <p className="mb-1"><img src={VectorImage} alt="" /> {course.course_duration} on demand video</p>
                                        <p className="mb-1"><img src={IconImage} alt="" /> {course.subject_exercises}</p>
                                        <p className="mb-1"><img src={NoteBookImage} alt="" /> {course.articles} </p>
                                    </div>
                                    <div className="col-6 col-sm-4">
                                        <p className="mb-1"><img src={DownloadImage} alt="" /> {course.resources_downloaded} </p>
                                        <p className="mb-1"><img src={CertificateImage} alt="" /> Certificate of completion</p>
                                    </div>
                                </div>



                                {/* Accordion */}
                                <div className="row my-5">
                                    <h4 className="course-title course-titles mb-3">Requirements</h4>
                                    <ul className="ms-3 d-flex" style={{ listStyle: 'disc' }}>
                                        <li className="me-4 me-md-5">44 sections</li>
                                        <li className="me-4 me-md-5">44 sections</li>
                                        <li>44 sections</li>
                                    </ul>

                                    <div className="bg-white mt-5 accordiantion_radius">
                                        <div className="common-accordion my-4 p-lg-4" id="accordionExample">
                                            {['Mechanics', 'Electricity', 'Energy', 'Optics', 'Thermodynamics', 'Astrophysics', 'Wave'].map((topic, index) => (
                                                <div className="accordion-item" key={index}>
                                                    <h2 className="accordion-header" id={`heading${index}`}>
                                                        <button
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${index}`}
                                                            aria-expanded={index === 0 ? 'true' : 'false'}
                                                            aria-controls={`collapse${index}`}
                                                        >
                                                            {topic}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse${index}`}
                                                        className={`accordion-collapse collapse ${index === 0 ? 'show' : ''}`}
                                                        aria-labelledby={`heading${index}`}
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            Content for {topic}.
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                <div className="row my-5">
                                    <h4 className="course-title course-titles mb-3">Requirements</h4>
                                    <ul className="ms-3" style={{ listStyle: 'disc' }}>
                                        {course.course_requirements}
                                        {/* <li>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</li>
        <li>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</li>
        <li>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</li>
        <li>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</li>
        <li>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</li> */}
                                    </ul>
                                </div>


                                <div class="row my-5">
                                    {createdBy.map((innerArray, i) => (
                                        <h4 class="course-title course-titles mb-4">Other Courses by {innerArray.name}</h4>

                                    ))}


                                    {courseDetailBasedOnUserid.map((innerArray, i) => (
                                        <div class="col-sm-4 mb-3">
                                            <div class="card spl_cards p-0">
                                                <div class="card-body">

                                                    <img src={`${Image_Base_Url}${course.course_image_path}`} alt="How to Solve Physics Problems" class="img-cover w-100 h-100 rounded-start" />
                                                    <h4 class="other_course-title mt-3">{innerArray.course_title}</h4>



                                                    <div class="row mb-4">
                                                        <p class="course-time mb-4">{innerArray.course_duration} Hours</p>

                                                        <p class="course-description text-muted">
                                                            {innerArray.course_description}
                                                        </p>

                                                    </div>


                                                    <div class="text-md-end">
                                                        <Link className="btn btn_cousre" to={`/CourseDetail/${innerArray.id}`}>
                                                            ₹ {innerArray.course_price}
                                                        </Link>
                                                        {/* <a href="#" class="btn btn_cousre w-100">₹ {innerArray.course_price}</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div class="col-sm-4 mb-3">
                                <div class="card spl_cards p-0">
                                    <div class="card-body">
                                       
                                        <img src={CourseImage} alt="How to Solve Physics Problems" class="img-cover w-100 h-100 rounded-start"/>
                                        <h4 class="other_course-title mt-3">How to Solve Physics Problems</h4>


                                       
                                        <div class="row mb-4">
                                            <p class="course-time mb-4">30.5 Hours</p>
                                        
                                            <p class="course-description text-muted">
                                                Simply dummy text of the printing and typesetting industry. Lorem
                                                Ipsum has been the industry's standard dummy text.
                                            </p>

                                        </div>

                                      
                                        <div class="text-md-end">
                                            <a href="#" class="btn btn_cousre w-100">₹ 999</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4 mb-3">
                                <div class="card spl_cards p-0">
                                    <div class="card-body">
                             
                                        <img src={CourseImage} alt="How to Solve Physics Problems" class="img-cover w-100 h-100 rounded-start"/>
                                        <h4 class="other_course-title mt-3">How to Solve Physics Problems</h4>


                                     
                                        <div class="row mb-4">
                                            <p class="course-time mb-4">30.5 Hours</p>
                                         
                                            <p class="course-description text-muted">
                                                Simply dummy text of the printing and typesetting industry. Lorem
                                                Ipsum has been the industry's standard dummy text.
                                            </p>

                                        </div>

                                       
                                        <div class="text-md-end">
                                            <a href="#" class="btn btn_cousre w-100">₹ 999</a>
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                                </div>

                            </div>

                        </div>

                    </div>

                    {/* <img class="shape_top_9 d-lg-block d-none" src={ShapeTop} alt=""/>
          
          <img class="shape_top_7 d-lg-block d-none" src={ShapeUnion} alt=""/> */}
                </section>
            </main>

            {/* <footer className="footer-section">
            <img className="shape_top_10 d-lg-block d-none" src={unionImage} alt="Decoration Shape" />
            <div className="container container-two">
                <div className="row gy-sm-5">
                    <div className="col-xl-6 col-sm-6">
                        <div className="position-relative mb-5">
                            <div className="search-box">
                                <input 
                                    type="text" 
                                    className="common-input common-input--lg pill shadow-sm auto-suggestion-input" 
                                    placeholder="Search" 
                                />
                                <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                    <img src={SearchIcon} alt="Search Icon" />
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-5 col-xs-6">
                                <div className="footer-widget">
                                    <ul className="footer-lists">
                                        <li className="footer-lists__item">
                                            <a href="/" className="footer-lists__link">Home</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/courses" className="footer-lists__link">Courses</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/feed" className="footer-lists__link">Feed</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/teachers" className="footer-lists__link">Teachers</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-sm-6 col-xs-6 ps-xl-5">
                                <div className="footer-widget">
                                    <ul className="footer-lists">
                                        <li className="footer-lists__item">
                                            <a href="/about-us" className="footer-lists__link">About Us</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/payment" className="footer-lists__link">Payment</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/profile" className="footer-lists__link">My Profile</a>
                                        </li>
                                        <li className="footer-lists__item">
                                            <a href="/institutes" className="footer-lists__link">Institutes</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="footer-widget mt-4">
                                <h3>Follow us on</h3>
                                <div className="footer-widget__social">
                                    <ul className="social-icon-list">
                                        <li className="social-icon-list__item">
                                            <a href="https://www.facebook.com" className="social-icon-list__link flx-center">
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li className="social-icon-list__item">
                                            <a href="https://www.youtube.com" className="social-icon-list__link flx-center">
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li className="social-icon-list__item">
                                            <a href="https://www.linkedin.com" className="social-icon-list__link flx-center">
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 offset-xl-1 col-sm-6">
                        <div className="footer-widget">
                            <div className="map-box mb-30 mt-5">
                                <li className="footer-lists__item">
                                    <a href="/" className="footer-lists__link">Location</a>
                                </li>
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                                    width="100%"
                                    height="300"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer> */}
            <Footer />
        </div>
    );
}

export default CourseDetail;
import React from 'react';
import '../admin/header/style.css';
//import './styles.css';
import { Header} from "../admin";
import { Adminmain, AdminNavbar } from "../admin";
function AdminDashboard() {
  return (
    <div>
      <Header />
      <div className="main-container">
      <AdminNavbar />
      <Adminmain />
      </div>
    </div>
  );
}

export default AdminDashboard;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-prev,
.slick-next {
  z-index: 10; /* Ensure the arrows are in front of the image */
  border: 1px solid black; /* Define the border for visibility */
  font-size: 24px; /* Adjust icon size */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 98%; /* Position the arrows vertically at the center of the image */
  transform: translateY(-50%);
  background-color: rgb(188, 181, 181);
}

.slick-prev {
  left: -60px; /* Adjust the left position to move it outside the slider */
}

.slick-next {
  right: -60px; /* Adjust the right position to move it outside the slider */
}

.slick-prev:hover, .slick-next:hover {
  background-color: orange; /* Change color on hover */
}

.slick-disabled {
  opacity: 0.5; /* Make the arrow look disabled when not clickable */
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/ProductSlider.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW,EAAE,gDAAgD;EAC7D,uBAAuB,EAAE,qCAAqC;EAC9D,eAAe,EAAE,qBAAqB;EACtC,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ,EAAE,8DAA8D;EACxE,2BAA2B;EAC3B,oCAAoC;AACtC;;AAEA;EACE,WAAW,EAAE,2DAA2D;AAC1E;;AAEA;EACE,YAAY,EAAE,4DAA4D;AAC5E;;AAEA;EACE,wBAAwB,EAAE,0BAA0B;AACtD;;AAEA;EACE,YAAY,EAAE,oDAAoD;AACpE","sourcesContent":[".slick-prev,\n.slick-next {\n  z-index: 10; /* Ensure the arrows are in front of the image */\n  border: 1px solid black; /* Define the border for visibility */\n  font-size: 24px; /* Adjust icon size */\n  width: 50px;\n  height: 50px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 98%; /* Position the arrows vertically at the center of the image */\n  transform: translateY(-50%);\n  background-color: rgb(188, 181, 181);\n}\n\n.slick-prev {\n  left: -60px; /* Adjust the left position to move it outside the slider */\n}\n\n.slick-next {\n  right: -60px; /* Adjust the right position to move it outside the slider */\n}\n\n.slick-prev:hover, .slick-next:hover {\n  background-color: orange; /* Change color on hover */\n}\n\n.slick-disabled {\n  opacity: 0.5; /* Make the arrow look disabled when not clickable */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

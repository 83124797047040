import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, clearError } from '../redux/slices/authSlice';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleImage from '../../src/assets/images/all/google.svg';
import FacebookImage from '../../src/assets/images/all/facebook.svg';
import loginImage from '../../src/assets/images/all/login.png';
import '../../src/assets/css/bootstrap.min.css';
import '../../src/assets/css/fontawesome-all.min.css';
import '../../src/assets/css/slick.css';
import '../../src/assets/css/line-awesome.min.css';
import '../../src/assets/css/main.css';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, successMessage } = useSelector((state) => state.auth);

  const [state, setState] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();
    const newErrors = validateForm(state);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      dispatch(loginUser(state));
    }
  };

  useEffect(() => {
    //const ACCESS_Role_ID = localStorage.getItem('ACCESS_Role_ID');
    if (successMessage) {
      if(ACCESS_Role_ID == 1) // student role id
        navigate('/studentdashboard');
      else if(ACCESS_Role_ID == 2) // teacher role id
        navigate('/TeacherProfile');
      else if(ACCESS_Role_ID == 3) // Institute role id
        navigate('/register');
      
  }
}, [successMessage, navigate]);

  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, [dispatch]);

  const validateForm = (data) => {
    const errors = {};
    if (!data.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }

    if (!data.password) {
      errors.password = 'Password is required';
    } else if (data.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }
    return errors;
  };

   const responseFacebook = (response) => {
    console.log('Facebook login response:', response);
    navigate('/TeacherProfile');
    // if (response.accessToken) {
    //     // Handle Facebook login logic (e.g., save user data or redirect)
    //     alert(JSON.stringify(response));
    //     console.log('Facebook Login Successful:', response);
    // } else {
    //     console.log('Facebook Login Failed');
    // }
};

  return (
    <section className="account d-flex" style={{ height: '100vh' }}>
      {/* Left side image section */}
      <div className="account__left d-none d-md-flex position-relative overflow-hidden">
        <img
          src={loginImage}
          alt="Login"
          className="account__img position-absolute w-100 h-100 object-cover"
        />
      </div>

      {/* Right side form section */}
      <div className="account__right pt-5 flx-align d-flex justify-content-center align-items-center">
        <div className="account-content">
          <h2 className="login-heading font-heading text-end mb-4">Login into Account</h2>
          <form onSubmit={handleSubmitClick}>
            <div className="row gy-3">
              <div className="col-12">
                <label htmlFor="email" className="form-label mb-2 font-18 font-heading fw-600">
                  Email
                </label>
                <input
                  type="email"
                  className="common-input common-input--bg"
                  id="email"
                  placeholder="name@example.com"
                  value={state.email}
                  onChange={handleChange}
                />
                {errors.email && <span className="error-message">{errors.email}</span>}
              </div>

              <div className="col-12">
                <label htmlFor="password" className="form-label mb-2 font-18 font-heading fw-600">
                  Password
                </label>
                <input
                  type="password"
                  className="common-input common-input--bg"
                  id="password"
                  placeholder="Password"
                  value={state.password}
                  onChange={handleChange}
                />
                {errors.password && <span className="error-message">{errors.password}</span>}
              </div>

              <div className="col-12">
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-main btn-lg w-100 pill"
                >
                  {loading ? 'Loading...' : 'Sign In'}
                </button>
                {error && <span className="error-message">{error}</span>}
              </div>
            </div>
          </form>

          <div className="col-sm-12 mb-3 mt-3">
            <p className="text font-14 text-center">Or</p>
          </div>

          <div className="col-12 mb-3">
            <button type="button" className="btn btn-outline-light btn-lg-icon btn-lg w-100 pill">
              <span className="icon icon-left">
                <img src={GoogleImage} alt="Google Icon" />
              </span>
              Sign in with Google
            </button>
          </div>

          <div class="col-12">
                                <button type="submit" class="btn btn-outline-light btn-lg-icon btn-lg w-100 pill" onClick={()=>responseFacebook()}>
                                    <span class="icon icon-left"><img src={FacebookImage} width="20px"
                                            alt=""/></span>
                                    Sign in with facebook
                                </button>
                            </div>

          {/* <div className="col-12">
            <FacebookLogin
              appId="922918029716335"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  onClick={renderProps.onClick}
                  className="btn btn-outline-light btn-lg-icon btn-lg w-100 pill"
                >
                  <span className="icon icon-left">
                    <img src={FacebookImage} width="20px" alt="Facebook Icon" />
                  </span>
                  Sign in with Facebook
                </button>
              )}
            />
          </div> */}

          <div className="col-sm-12 mt-3">
            <p className="text font-14 text-center">
              <Link to="/register" className="link text-main text-decoration-underline fw-500">
                Create Account
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;

import React, { useEffect } from 'react';
//import '../../'
import '../../src/assets/js/jquery-3.7.1.min.js';
import '../../src/assets/js/boostrap.bundle.min.js';
import '../../src/assets/js/countdown.js';
import '../../src/assets/js/slick.min.js';
import '../../src/assets/js/jquery.magnific-popup.js';
import '../../src/assets/js/marquee.min.js';
import '../../src/assets/js/main.js';

const ScriptsLoader = () => {
    // useEffect(() => {
    //     // Create script elements for each JS file you want to load
    //     const scripts = [
    //         '../../src/assets/js/jquery-3.7.1.min.js',
    //         '../../src/assets/js/boostrap.bundle.min.js',
    //         '../../src/assets/js/countdown.js',
    //         '../../src/assets/js/slick.min.js',
    //         '../../src/assets/js/jquery.magnific-popup.js',
    //         '../../src/assets/js/marquee.min.js',
    //         '../../src/assets/js/main.js',
    //     ];

    //     scripts.forEach(scriptSrc => {
    //         const script = document.createElement('script');
    //         script.src = scriptSrc;
    //         script.async = true;
    //         document.body.appendChild(script);
    //         // Clean up function to remove script when component unmounts
    //         return () => {
    //             document.body.removeChild(script);
    //         };
    //     });
    // }, []);

    // return (
    //     <div>
    //         <h1>Your Component</h1>
    //     </div>
    // );
};
export default ScriptsLoader;
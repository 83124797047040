import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/fontawesome-all.min.css';
import '../assets/css/slick.css';
import '../assets/css/line-awesome.min.css';
import '../assets/css/main.css';
import '../assets/css/responsive.css';


import shapeTop from '../../src/assets/images/all/shape_1.png';
import shapeUnion from '../../src/assets/images/icons/Unions.svg';
import scanIcon from '../../src/assets/images/all/scan-svgrepo-com 1.svg';
import searchIcon from '../../src/assets/images/all/search.svg';
import profileImage from '../../src/assets/images/all/profiel.png';
import heartIcon from '../../src/assets/images/icons/iconoir_heart.svg';
import commentIcon from '../../src/assets/images/icons/majesticons_comment-line.svg';
import sendIcon from '../../src/assets/images/icons/mingcute_send-line.svg';
import menuDotsIcon from '../../src/assets/images/icons/solar_menu-dots-bold.svg';
import mediaIcon from '../../src/assets/images/icons/Group 85.svg';
import mapShape from '../../src/assets/images/icons/Union-1.svg';
import LogoImage from '../../src/assets/images/all/scan-svgrepo-com 1.svg';
import { Footer, Navbar } from '../components';

function Upload() {
    useEffect(() => {
        // Modal Initialization
        const bootstrap = require('bootstrap');
        const myModal = new bootstrap.Modal(document.getElementById('postModal'), {
            backdrop: 'static',
            keyboard: false
        });
        myModal.show();
    }, []);

    return (
        <div>
            {/* Progress Wrap */}
            <div className="progress-wrap">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>

            {/* Mobile Menu */}
            <div className="mobile-menu d-lg-none d-block">
                <button type="button" className="close-button">
                    <i className="las la-times"></i>
                </button>
                <div className="mobile-menu__inner">
                    <div className="mobile-menu__menu">
                        <ul className="nav-menu flx-align nav-menu--mobile">
                            <li className="nav-menu__item activePage"><Link to="/" className="nav-menu__link">Home</Link></li>
                            <li className="nav-menu__item"><Link to="/course" className="nav-menu__link">Courses</Link></li>
                            <li className="nav-menu__item"><Link to="/feed" className="nav-menu__link">Feed</Link></li>
                            <li className="nav-menu__item"><Link to="/videos" className="nav-menu__link">Videos</Link></li>
                            <li className="nav-menu__item"><Link to="/teachers" className="nav-menu__link">Teachers</Link></li>
                            <li className="nav-menu__item"><Link to="/institutes" className="nav-menu__link">Institutes</Link></li>
                            <li className="nav-menu__item has-submenu">
                                <Link to="#" className="nav-menu__link">My Profile</Link>
                                <ul className="nav-submenu">
                                    <li className="nav-submenu__item"><Link to="/edit-profile" className="nav-submenu__link">Edit Profile</Link></li>
                                    <li className="nav-submenu__item"><Link to="/enrolled-courses" className="nav-submenu__link">Enrolled Courses</Link></li>
                                    <li className="nav-submenu__item"><Link to="/posts" className="nav-submenu__link">Your Posts</Link></li>
                                    <li className="nav-submenu__item"><Link to="/certificates" className="nav-submenu__link">Certificates</Link></li>
                                    <li className="nav-submenu__item"><Link to="/chats" className="nav-submenu__link">Chats</Link></li>
                                </ul>
                            </li>
                            <li className="nav-menu__item"><Link to="/" className="nav-menu__link">Scan OR Code</Link></li>
                        </ul>
                        <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
                            <Link to="/login" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* <img className="shape_top d-lg-block d-none" src={shapeTop} alt="Shape Top" />
            <img className="shape_top_6 d-xl-block d-none" src={shapeUnion} alt="Shape Union" /> */}

            <main className="change-gradient">
            
                {/* <header>
                    <div className="logo"></div>
                  
                    <div className="header-menu d-lg-block d-none">
                        <ul className="nav-menu flx-align">
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Home</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="course.html" className="nav-menu__link">Courses</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Feed</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Learn with us</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Teachers</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">Institute</a>
                            </li>
                            <li className="nav-menu__item">
                                <a href="/" className="nav-menu__link">About Us</a>
                            </li>
                        </ul>
                    </div>
                   
                    <div className="header-right flx-align pe-lg-5">
                        <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
                            <a href="/" className="btn btn-main pill sign_btn px-sm-4">Signup/Login</a>
                            <a href="/" className="btn cicrle_btn btn-main">
                                <img src={LogoImage} alt="Logo" />
                            </a>
                        </div>
                        <button type="button" className="toggle-mobileMenu d-lg-none">
                            <i className="las la-bars"></i>
                        </button>
                    </div>
                </header> */}

                <Navbar/>

                {/* Banner Section */}
                <section className="hero section-bg z-index-1">
                    <div className="container py-5 container-two">
                        <div className="row align-items-center gy-sm-5 gy-4">
                            <div className="col-lg-12 order-1 order-lg-0" style={{ marginTop: '110px' }}>
                                <div className="position-relative">
                                    <div className="search-box">
                                        <input type="text" className="common-input common-input--lg pill shadow-sm auto-suggestion-input" placeholder="Search" />
                                        <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                            <img src={searchIcon} alt="Search" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Card Section */}
                <section className="pb-5">
                    <div className="container">
                        <div className="row gy-3 justify-content-center">
                            <div className="col-lg-10 col-sm-10 col-md-12">
                                <div className="feed_card card mb-3">
                                    <div className="row">
                                        <div className="col-12">
                                            <span className="d-flex align-items-center">
                                                <div className="feed_img me-3">
                                                    <img src={profileImage} alt="Profile" />
                                                </div>
                                                @prakharmishra
                                            </span>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                <div className="col-10">
                                                    <img src={heartIcon} alt="Heart" />
                                                    <img src={commentIcon} alt="Comment" />
                                                    <img src={sendIcon} alt="Send" />
                                                </div>
                                                <div className="col-2 text-end">
                                                    <img src={menuDotsIcon} alt="Menu Dots" />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="my-4">
                                            Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text since the 1500s.
                                        </p>
                                        <p>Add a comment...</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Modal Structure */}
                <div className="modal upload_modal fade show" id="postModal" tabIndex="-1" aria-labelledby="postModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg upload_popup">
                        <div className="modal-content">
                            <div className="modal-header">
                                <span className="d-flex align-items-center">
                                    <div className="feed_img me-3">
                                        <img src={profileImage} alt="Profile Image" className="rounded-circle" />
                                    </div>
                                    <div>
                                        <p>Aariz Mahmood</p>
                                        <small className="text-muted ms-2">Public</small>
                                    </div>
                                </span>
                                <button type="button" className="btn-close btn_cls uplaod_close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <textarea className="form-control" placeholder="Write what's in your mind"></textarea>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <span className="text-start">
                                    <img className="me-2" src={mediaIcon} alt="Media Icon" /> Media
                                </span>
                                <button type="button" className="bbtn btn_cousre">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Footer */}

                <Footer/>
                {/* <footer className="footer-section">
                    <img className="shape_top_10 d-lg-block d-none" src={mapShape} alt="Decoration Shape" />
                    <div className="container container-two">
                        <div className="row gy-sm-5">
                            <div className="col-xl-6 col-sm-6">
                                <div className="position-relative mb-5">
                                    <div className="search-box">
                                        <input type="text" className="common-input common-input--lg pill shadow-sm auto-suggestion-input" placeholder="Search" />
                                        <button type="submit" className="btn btn-main spl_burron btn-icon icon border-0">
                                            <img src={searchIcon} alt="Search Icon" />
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-5 col-xs-6">
                                        <div className="footer-widget">
                                            <ul className="footer-lists">
                                                <li><Link to="/" className="footer-lists__link">Home</Link></li>
                                                <li><Link to="/courses" className="footer-lists__link">Courses</Link></li>
                                                <li><Link to="/feed" className="footer-lists__link">Feed</Link></li>
                                                <li><Link to="/teachers" className="footer-lists__link">Teachers</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-xs-6 ps-xl-5">
                                        <div className="footer-widget">
                                            <ul className="footer-lists">
                                                <li><Link to="/about-us" className="footer-lists__link">About Us</Link></li>
                                                <li><Link to="/payment" className="footer-lists__link">Payment</Link></li>
                                                <li><Link to="/profile" className="footer-lists__link">My Profile</Link></li>
                                                <li><Link to="/institutes" className="footer-lists__link">Institutes</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="footer-widget mt-4">
                                        <h3>Follow us on</h3>
                                        <div className="footer-widget__social">
                                            <ul className="social-icon-list">
                                                <li><a href="https://www.facebook.com" className="social-icon-list__link flx-center"><i className="fab fa-facebook-f"></i></a></li>
                                                <li><a href="https://www.youtube.com" className="social-icon-list__link flx-center"><i className="fab fa-youtube"></i></a></li>
                                                <li><a href="https://www.linkedin.com" className="social-icon-list__link flx-center"><i className="fab fa-linkedin-in"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 offset-xl-1 col-sm-6">
                                <div className="footer-widget">
                                    <div className="map-box mb-30 mt-5">
                                        <li><Link to="/" className="footer-lists__link">Location</Link></li>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                                            width="100%" height="300" style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </main>
        </div>
    );
}

export default Upload;

import React, { useState } from 'react';
import heroImage from '../../src/assets/images/all/image2q.png';
import searchIcon from '../../src/assets/images/all/search.svg';
import with_pad from '../../src/assets/images/all/with_pad.png';
import teacher1 from '../../src/assets/images/all/teacher_1.png';
import teacher2 from '../../src/assets/images/all/teacher_2.png';
import teacher3 from '../../src/assets/images/all/teacher_3.png';
import teacher4 from '../../src/assets/images/all/teacher_4.png';
import videoImage from '../../src/assets/images/all/video.png';
import shape1 from '../../src/assets/images/all/shape_2.png';
import shape2 from '../../src/assets/images/all/shape_3.png';
import shape3 from '../../src/assets/images/all/shape_4.png';
import shape4 from '../../src/assets/images/all/shape_5.png';
import brand1 from '../../src/assets/images/all/brand_1.png';
import brand2 from '../../src/assets/images/all/brand_2.png';
import brand3 from '../../src/assets/images/all/brand_3.png';
import brand4 from '../../src/assets/images/all/brand_4.png';
import brand5 from '../../src/assets/images/all/brand_5.png';
import brand6 from '../../src/assets/images/all/brand_6.png';
import blog1 from '../../src/assets/images/all/blog.png';
import blog2 from '../../src/assets/images/all/blog_2.png';

import ProductSlider from "../components/ProductSlider";

const Home = () => {
  const [searchQuery, setSearchQuery] = useState(""); // State to store search input

  const teachers = [
    { id: 1, name: 'Physics Walla', image: teacher1 },
    { id: 2, name: 'Math Guru', image: teacher2 },
    { id: 3, name: 'Chemistry Master', image: teacher3 },
    { id: 4, name: 'Biology Expert', image: teacher4 },
  ];

  // Handle the search button click
  const handleSearch = () => {
    if (searchQuery) {
      console.log('Searching for:', searchQuery);  // You can replace this with actual search logic
      alert(`Searching for: ${searchQuery}`);  // Or redirect to a search results page
    } else {
      alert('Please enter a search term');
    }
  };

  return (
    <>
      {/* Hero Section */}
      <section className="hero section-bg z-index-1">
        <div className="container container-two">
          <div className="row align-items-center gy-sm-5 gy-4">
            <div className="col-lg-7">
              <div className="hero-inner position-relative pe-lg-5">
                <h1  className="hero-inner__title">Let's Learn Something <br /> New</h1>
                <div className="hero-inner__title_2">
                  <img src={with_pad} alt="Learning pad" />
                </div>
                <p className="hero-inner__desc font-18">
                  Explore the best premium themes and plugins available for sale. Our unique collection is hand-curated by experts. Find and buy the perfect premium theme today.
                </p>
                {/* <div className="position-relative">
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                      placeholder="Search"
                      value={searchQuery}  // Bind input value to state
                      onChange={(e) => setSearchQuery(e.target.value)}  // Update state on input change
                    />
                    <button
                      type="submit"
                      className="btn btn-main spl_button btn-icon icon border-0"
                      onClick={handleSearch}  // Handle search button click
                    >
                      <img src={searchIcon} alt="Search" style={{
          width: '50px', // Increase the width of the icon
          height: '50px', // Increase the height of the icon
        }} />
                    </button>
                  </div>
                </div> */}

<div className="position-relative">
  <div className="search-box">
    <input
      type="text"
      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
      placeholder="Search"
      value={searchQuery}  // Bind input value to state
      onChange={(e) => setSearchQuery(e.target.value)}  // Update state on input change
    />
    <button
      type="submit"
      className="btn btn-main spl_button btn-icon icon border-0"
      onClick={handleSearch}  // Handle search button click
      style={{ padding: '0.5rem' }}  // Adjust padding if needed
    >
      <img
        src={searchIcon}
        alt="Search"
        style={{
          width: '30px', // Increase the width of the icon
          height: '30px', // Increase the height of the icon
        }}
      />
    </button>
  </div>
</div>

              </div>
            </div>
            <div className="col-lg-5">
              <div className="hero-thumb">
                <img src={heroImage} alt="Hero image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Product Slider Section */}
      <section className="selling-product padding-y-120 position-relative z-index-1 overflow-hidden">
        <div className="container container-two">
          <div className="section-heading style-left style-white position-absolute flx-between max-w-unset gap-4">
            <div>
              <h3 className="section-heading__title">Trusted <br /> By</h3>
            </div>
          </div>
          <div className="selling-product-slider col-sm-4 mx-auto" style={{ position: 'relative' }}>
            <ProductSlider />
          </div>
        </div>
        <img className="shape_top_2" src={shape1} alt="Shape 1" />
        <img className="shape_top_3" src={shape2} alt="Shape 2" />
      </section>

      {/* Most Followed Teachers Section */}
      <section className="popular padding-y-120 overflow-hidden">
         <div className="container">
           <div className="section-heading__1 mb-64 text-start">
            <h5 className="section-heading__1 text-start">Most Followed Teachers</h5>
           </div>
           <div className="row gy-4">
             {teachers.map((teacher) => (
              <div key={teacher.id} className="col-lg-3" style={{ textAlign: 'center', position: 'relative' }}>
                <div className="most_follwed"></div>
                <a href="#" className="link w-100" style={{ position: 'relative', zIndex: 1 }}>
                  <img
                    src={teacher.image}
                    alt={teacher.name}
                    className="cover-img most_follwed_img"
                  />
                </a>
                <a href="#" className="most_follwed_name">
                  {teacher.name}
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Interactive Online Courses Section */}
     

      <section className="popular padding-y-120 overflow-hidden" style={{position:'relative'}}>
            <div className="container">
                <div className="section-heading__1 mb-64 text-start position-absolute">
                    <h2 className="section-heading__1 intractive text-start Unbounded">Interactive Online Courses</h2>
                </div>
                <div className="row gy-4 align-items-center position-relative">
                    <div className="svg-wrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" className="desktop-svg d-none d-sm-block" width="1158"
                            height="561" viewBox="0 0 1158 561" fill="none">
                            <path
                                d="M0 350.752C0 323.845 17.9121 300.229 43.822 292.974L1081.82 2.33013C1120.08 -8.38263 1158 20.3771 1158 60.1079V501C1158 534.137 1131.14 561 1098 561H60C26.8629 561 0 534.137 0 501V350.752Z"
                                fill="#FF7A00" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className="mobile-svg d-block d-sm-none"
                            viewBox="0 0 366 178" fill="none">
                            <path
                                d="M0 109.446C0 102.268 4.78002 95.9686 11.6928 94.0363L345.693 0.676298C355.894 -2.17507 366 5.49373 366 16.0856V162C366 170.837 358.837 178 350 178H16C7.16344 178 0 170.837 0 162V109.446Z"
                                fill="#FF7A00" />
                        </svg>
                    </div>

                    <div className="row spcl_clss">
                       
                        <div className="col-lg-6 order-1 order-lg-0 position-relative" style={{zIndex:1}}>
                            <div className="p-sm-5" style={{position:'relative',zIndex:1}}>
                                <p className="text-black">Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's
                                    standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                    type and
                                    scrambled it to make a type specimen book.</p>
                                <div className="d-flex this_flex flex-wrap">
                                    <p className="text-black"><strong>5,000+ Teachers</strong></p>
                                    <p className="text-black"><strong>20,000+ Courses</strong></p>
                                    <p className="text-black"><strong>100 Schools</strong></p>
                                </div>
                            </div>
                        </div>

                     
                        <div className="col-lg-6 position-relative order-0 order-lg-1">
                            <div style={{ position: 'relative' }} className="cust_img">
                                <img src={videoImage} alt="Ali Abdaal"
                                    // style="position: relative; z-index: 2; width: 100%; border-radius: 10px;"
                                    style={{
                                      position: 'relative',
                                      zIndex: 2,
                                      width: '100%',
                                      borderRadius: '10px',
                                     
                                    }}
                                    />
                              
                                <div className="mt-3 text-lg-center text-end">
                                    <a href="" className="btn btn-main px-4">Ali Abdaal</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

      {/* Trusted By Section */}
      <section className="popular padding-y-120 overflow-hidden" style={{ position: 'relative' }}>
        <img className="shape_top_4" src={shape3} alt="Shape 4" />
        <img className="shape_top_5" src={shape4} alt="Shape 5" />

        <div className="container">
          <div className="section-heading mb-64 text-center">
            <h5 className="text-center section-heading__1">Trusted By</h5>
            <p>Trusted by 100 of institutes</p>
          </div>
          <div className="row gy-4">
            <div className="col-lg-2">
              <span className="popular-item__icon">
                <img src={brand1} alt="Brand 1" />
              </span>
            </div>
            <div className="col-lg-2">
              <span className="popular-item__icon">
                <img src={brand2} alt="Brand 2" />
              </span>
            </div>
            <div className="col-lg-2">
              <span className="popular-item__icon">
                <img src={brand3} alt="Brand 3" />
              </span>
            </div>
            <div className="col-lg-2">
              <span className="popular-item__icon">
                <img src={brand4} alt="Brand 4" />
              </span>
            </div>
            <div className="col-lg-2">
              <span className="popular-item__icon">
                <img src={brand5} alt="Brand 5" />
              </span>
            </div>
            <div className="col-lg-2">
              <span className="popular-item__icon">
                <img src={brand6} alt="Brand 6" />
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* Learn Something New Section */}
      <section className="learn-new padding-y-120">
        <div className="container">
          <div className="row">
            {/* Left Side: Heading and Paragraphs */}
            <div className="col-lg-6">
              <h2 className="section-title">Learn Something New Today</h2>
              <p>
                Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took.
              </p>
              <p>
                Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>

            {/* Right Side: Card List */}
            <div className="col-lg-6">
              <div className="course-card d-flex align-items-center mb-4">
                <img src={blog1} alt="Revise for Exams" className="card-img" />
                <div className="card-content ms-3">
                  <h4 className="course-title">How to Revise for Exams Quickly</h4>
                  <p className="author-name">Santosh Agarwal</p>
                  <p className="course-duration">12 mins</p>
                </div>
              </div>
              <div className="course-card d-flex align-items-center">
                <img src={blog2} alt="Back to School" className="card-img" />
                <div className="card-content ms-3">
                  <h4 className="course-title">Back to School Study Better</h4>
                  <p className="author-name">Rajesh Kumar</p>
                  <p className="course-duration">9 mins</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
import React from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/css/fontawesome-all.min.css";
import "../assets/css/slick.css";
import "../assets/css/line-awesome.min.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";

import MajesticonImage from "../../src/assets/images/icons/majesticons_comment-line.svg";
import HeartImage from "../../src/assets/images/icons/iconoir_heart.svg";
import SearchImage from "../../src/assets/images/all/search.svg";
import LogoImage from "../../src/assets/images/all/scan-svgrepo-com 1.svg";
import ProfileImage from "../../src/assets/images/all/profiel.png";
import FeedImage from "../../src/assets/images/all/feed-details.png";
import MingCuteImage from "../../src/assets/images/icons/mingcute_send-line.svg";
import SolarMenu from "../../src/assets/images/icons/solar_menu-dots-bold.svg";
import unionImage from "../../src/assets/images/icons/Union-1.svg";
import SearchIcon from "../../src/assets/images/all/search.svg";
import FeedImage1 from "../../src/assets/images/all/fedd.png";
import ClarityImage from "../../src/assets/images/icons/clarity_sort-by-line.svg";
import AkarImage from "../../src/assets/images/icons/akar-icons_video.svg";
import BookImage from '../../src/assets/images/icons/mi_book.svg'
import { Footer, Navbar } from "../components";

function CourseVideo() {
  return (
    <div className="App">
      {/* Scroll to Top */}
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <nav className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">
                  Home
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="/course" className="nav-menu__link">
                  Courses
                </a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">
                  My Profile
                </a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item">
                    <a href="/" className="nav-submenu__link">
                      Edit Profile
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
              <a href="/" className="btn btn-main pill sign_btn px-sm-4">
                Signup/Login
              </a>
            </div>
          </nav>
        </div>
      </div>

      {/* Header */}
      {/* <header>
        <div className="logo"></div>
        <div className="header-menu d-lg-block d-none">
          <ul className="nav-menu flx-align">
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Home
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/course.html" className="nav-menu__link">
                Courses
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Feed
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Learn with us
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Teachers
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                Institute
              </a>
            </li>
            <li className="nav-menu__item">
              <a href="/" className="nav-menu__link">
                About Us
              </a>
            </li>
          </ul>
        </div>
        <div className="header-right flx-align pe-lg-5">
          <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
            <a href="/" className="btn btn-main pill sign_btn px-sm-4">
              Signup/Login
            </a>
            <a href="/" className="btn cicrle_btn btn-main">
              <img src={LogoImage} alt="Logo" />
            </a>
          </div>
          <button type="button" className="toggle-mobileMenu d-lg-none">
            <i className="las la-bars"></i>
          </button>
        </div>
      </header> */}

        <Navbar/>
      {/* Main Content */}
      <main className="change-gradient">
        <section className="hero section-bg z-index-1">
          <div className="container py-5 container-two">
            <div className="row align-items-center gy-sm-5 gy-4">
              <div
                className="col-lg-12 order-1 order-lg-0"
                style={{ marginTop: "110px" }}
              >
                <div className="position-relative">
                  <div className="search-box">
                    <input
                      type="text"
                      className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                      placeholder="Search"
                    />
                    <button
                      type="submit"
                      className="btn btn-main spl_burron btn-icon icon border-0"
                    >
                      <img src={SearchImage} alt="Search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-5">
          <div className="container cours_deataisl">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-sm-10 col-md-12">
                <div className="course-details">
                  <div className="position-relative cousrse_img_height">
                    <img
                      src={FeedImage}
                      alt="How to Solve Physics Problems"
                      className="img-fluid rounded w-100 h-100"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <h4 className="course-titles my-4">
                    How to Solve Physics Problems
                  </h4>
                </div>

               

<div class="row">
                            <div class="col-sm-4">
                                <div class="d-flex align-items-start">
                                    <div class="feed_img me-3">
                                    <img
                          src={ProfileImage}
                          alt="Profile"
                          className="rounded-circle"
                        />
                                    </div>
                                    <div>
                                        <p class="learm-tilte">Aariz Mahmood</p>
                                        <small class="learm-tilte2">Public</small>
                                    </div>


                                </div>

                            </div>
                            <div class="col-sm-8 d-flex mt-3 mt-lg-0 justify-content-between">
                                <div>
                                    <button type="button" class="bbtn btn_cousre share_button">Follow</button>
                                </div>
                                <div class="">
                                    <button type="button" class="bbtn btn_cousre share_button me-3">Share</button>
                                    <a type="button" class="">
                                    <img src={SolarMenu} alt="Options" />
                                    </a>
                                </div>

                            </div>
                        </div>

                <div className="row">
                  <div className="bg-white mt-5 course_video accordiantion_radius">
                    <div
                      className="common-accordion my-4 mb-0 px-lg-4 pb-4"
                      id="accordionExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button pt-0 upnext"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Up Next
                          </button>
                        </h2>
                        
                          <div id="collapseOne" class="accordion-collapse collapse show "
                                            aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div class="accordion-body pb-0">
                                                <div class="row gy-4 pb-0">
                                                    <div class="col-sm-12">

                                                        <div class="row">
                                                            <div class="col-sm-3 col-6">
                                                                <div class="imgss">
                                                                <img src={FeedImage1} alt="Feed" />
                                                                </div>

                                                            </div>
                                                            <div class="col-sm-4 col-6 d-flex  ms-md-4">
                                                                <div>
                                                                <img src={AkarImage} alt="Video Icon" />
                                                                </div>
                                                                <div class="ms-md-3 ms-1">
                                                                    <p class="course_video_text">Mechanics</p>
                                                                    <p class="course_video_time">10 min</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">

                                                        <div class="row">

                                                            <div class="col-sm-3 col-6">
                                                                <div class="imgss">
                                                                <img src={FeedImage1} alt="Feed" />
                                                                </div>

                                                            </div>
                                                            <div class="col-sm-4 col-6 d-flex  ms-md-4">
                                                                <div>
                                                                <img src={AkarImage} alt="Video Icon" />
                                                                </div>
                                                                <div class="ms-md-3 ms-1">
                                                                    <p class="course_video_text">Mechanics</p>
                                                                    <p class="course_video_time">10 min</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">

                                                        <div class="row">

                                                            <div class="col-sm-3 col-6">
                                                                <div class="imgss">
                                                                <img src={FeedImage1} alt="Feed" />
                                                                </div>

                                                            </div>
                                                            <div class="col-sm-4 col-6 d-flex  ms-md-4">
                                                                <div>
                                                                <img src={AkarImage} alt="Video Icon" />
                                                                </div>
                                                                <div class="ms-md-3 ms-1">
                                                                    <p class="course_video_text">Mechanics</p>
                                                                    <p class="course_video_time">10 min</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">

                                                        <div class="row">

                                                            <div class="col-sm-3 col-6">
                                                                <div class="imgss">
                                                                <img src={FeedImage1} alt="Feed" />
                                                                </div>

                                                            </div>
                                                            <div class="col-sm-4 col-6 d-flex  ms-md-4">
                                                                <div>
                                                                    <img src={BookImage} alt=""/>
                                                                </div>
                                                                <div class="ms-md-3">
                                                                    <p class="course_video_text">Mechanics</p>
                                                                    <p class="course_video_time">10 min</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <p class="learm-tilte2 text-end">View More</p>
                                            </div>
                                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="bg-white mt-5 p-4 pb-3 accordiantion_radius comment_box"
                    style={{ minHeight: "300px" }}
                  >
                    <div className="row">
                      <div className="col-10">
                        <p className="learm-tilte2">Ask Questions</p>
                      </div>
                      <div className="col-2 text-end">
                        <img src={ClarityImage} alt="Sort by" />
                        <span className="learm-tilte2">Sort by</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex align-items-start">
                        <div className="feed_img me-3">
                          <img
                            src={ProfileImage}
                            alt="Profile Image"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="sp_row">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="common-input common-input--bg comment_box_input"
                              placeholder="text"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="d-flex align-items-start">
                        <div className="feed_img me-3">
                          <img
                            src={ProfileImage}
                            alt="Profile Image"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="sp_row">
                          <p className="course_video_time">@Aariz Mahmood</p>
                          <p className="text-muted">
                          Best way to revise All Chapters in Physical Chemistry 1: Mind Map
                          or Summary lec 2: Combined class Questions (Yakeen)3: PYQs
                          </p>
                          <p className="text-center replay">Reply</p>
                        </div>
                        
                        
                      </div>
                      <div className="d-flex align-items-start">
                        <div className="feed_img me-3">
                          <img
                            src={ProfileImage}
                            alt="Profile Image"
                            className="rounded-circle"
                          />
                        </div>
                        <div className="sp_row">
                          <p className="course_video_time">@Aariz Mahmood</p>
                          <p className="text-muted">
                          Best way to revise All Chapters in Physical Chemistry 1: Mind Map
                          or Summary lec 2: Combined class Questions (Yakeen)3: PYQs
                          </p>
                          <p className="text-center replay">Reply</p>
                        </div>
                        
                        
                      </div>
                      
                    </div>
                    <p className="learm-tilte2 text-end">View More</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <Footer/>
      {/* <footer className="footer-section">
        <img
          className="shape_top_10 d-lg-block d-none"
          src={unionImage}
          alt="Decoration Shape"
        />
        <div className="container container-two">
          <div className="row gy-sm-5">
            <div className="col-xl-6 col-sm-6">
              <div className="position-relative mb-5">
                <div className="search-box">
                  <input
                    type="text"
                    className="common-input common-input--lg pill shadow-sm auto-suggestion-input"
                    placeholder="Search"
                  />
                  <button
                    type="submit"
                    className="btn btn-main spl_burron btn-icon icon border-0"
                  >
                    <img src={SearchIcon} alt="Search Icon" />
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5 col-xs-6">
                  <div className="footer-widget">
                    <ul className="footer-lists">
                      <li className="footer-lists__item">
                        <a href="/" className="footer-lists__link">
                          Home
                        </a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="/courses" className="footer-lists__link">
                          Courses
                        </a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="/feed" className="footer-lists__link">
                          Feed
                        </a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="/teachers" className="footer-lists__link">
                          Teachers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-6 col-xs-6 ps-xl-5">
                  <div className="footer-widget">
                    <ul className="footer-lists">
                      <li className="footer-lists__item">
                        <a href="/about-us" className="footer-lists__link">
                          About Us
                        </a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="/payment" className="footer-lists__link">
                          Payment
                        </a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="/profile" className="footer-lists__link">
                          My Profile
                        </a>
                      </li>
                      <li className="footer-lists__item">
                        <a href="/institutes" className="footer-lists__link">
                          Institutes
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="footer-widget mt-4">
                  <h3>Follow us on</h3>
                  <div className="footer-widget__social">
                    <ul className="social-icon-list">
                      <li className="social-icon-list__item">
                        <a
                          href="https://www.facebook.com"
                          className="social-icon-list__link flx-center"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li className="social-icon-list__item">
                        <a
                          href="https://www.youtube.com"
                          className="social-icon-list__link flx-center"
                        >
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li className="social-icon-list__item">
                        <a
                          href="https://www.linkedin.com"
                          className="social-icon-list__link flx-center"
                        >
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-5 offset-xl-1 col-sm-6">
              <div className="footer-widget">
                <div className="map-box mb-30 mt-5">
                  <li className="footer-lists__item">
                    <a href="/" className="footer-lists__link">
                      Location
                    </a>
                  </li>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.330999415546!2d78.44399507462819!3d17.44386460117675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb917f6ecb6987%3A0x7d6d85b768a4c33b!2sFg%20Designs!5e0!3m2!1sen!2sin!4v1707451998655!5m2!1sen!2sin"
                    width="100%"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </div>
  );
}

export default CourseVideo;

// import { Navbar, Main, Footer,ScriptsLoader } from "../components";
// //import { ScriptsLoader } from "../components/ScriptsLoader";
// function Home() {
//   return (
//     <>
//       <Navbar />
//       <Main />
//       <Footer />
//       <ScriptsLoader />
//     </>
//   )
// }

// export default Home


import { Navbar, Main, Footer, ScriptsLoader } from "../components";

function Home() {
  return (
    <>
      <div style={{ position: 'fixed', width: '100%', top: 0, zIndex: 1000 }}>
        <Navbar />
      </div>
      <div style={{ marginTop: '70px' }}>
        <Main />
      </div>
      <Footer />
      <ScriptsLoader />
    </>
  );
}

export default Home;

import React, { useState } from 'react';
import '../../src/assets/css/bootstrap.min.css';
import '../../src/assets/css/fontawesome-all.min.css';
import '../../src/assets/css/slick.css';
import '../../src/assets/css/line-awesome.min.css';
import '../../src/assets/css/main.css';
import loginImage from '../../src/assets/images/all/login.png';

const OtpPage = () => {
    const [otp, setOtp] = useState(new Array(6).fill(''));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return; // Ensure it's a number

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        // Move to the next input automatically if the current one is filled
        if (element.value && element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        // If the backspace key is pressed and the input is empty
        if (e.key === 'Backspace' && otp[index] === '') {
            // Focus on the previous input box
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // You can handle OTP submission here
        console.log("Entered OTP:", otp.join(''));
    };

    // Inline styles for input box to match the design
    const inputStyle = {
        width: '80px',
        height: '80px',
        margin: '0 5px',
        fontSize: '24px',
        textAlign: 'center',
        borderRadius: '40px', 
        border: '1px solid #D9D9D9',
        outline: 'none',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        transition: 'box-shadow 0.3s ease',
        backgroundColor: '#f3f3f3',
    };
    

    const inputFocusStyle = {
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
        borderColor: '#6A67CE',
        backgroundColor:'#6A67CE',
    };

    return (
        <section className="account d-flex" style={{ height: '100vh' }}>
            {/* Left side image section */}
            <div className="account__left d-none d-md-flex position-relative overflow-hidden">
                <img
                    src={loginImage}
                    alt="Login"
                    className="account__img position-absolute w-100 h-100 object-cover"
                />
            </div>

            {/* Right side form section */}
            <div className="account__right pt-5 flx-align d-flex justify-content-center align-items-center">
                <div className="account-content">
                    <h2 className="login-heading font-heading text-end mb-4">Login into Account</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row gy-3 d-flex justify-content-center align-items-center" style={{ minHeight: '500px' }}>
                            {/* Verification Code Inputs */}
                            <div className="col-12">
                                <label htmlFor="verification-code" className="form-label mb-2 font-18 font-heading fw-600">
                                    Enter Verification Code
                                </label>
                                <p className="mb-3">You might have received an OTP on your email id or mobile phone</p>
                                <div className="d-flex">
                                    {otp.map((value, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            className="spl_input common-input common-input--bg verification-code"
                                            maxLength="1"
                                            value={value}
                                            onChange={(e) => handleChange(e.target, index)}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            onFocus={(e) => e.target.select()}
                                            style={{
                                                ...inputStyle,
                                                ...(value !== '' && inputFocusStyle),
                                            }}
                                            required
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default OtpPage;

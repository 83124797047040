export { default as Home } from './Home';
export { default as AboutPage } from './AboutPage';
export { default as ContactPage } from './ContactPage';
export { default as Cart } from './Cart';
export { default as Login } from './Login';
export { default as Register } from './Register';
export { default as RegisterOtp } from './RegisterOtp';
export { default as Checkout } from './Checkout';
export { default as PageNotFound } from './PageNotFound';
export { default as Studentdashboard } from '../pages/Student/StudentDashboard';
export { default as Teacher } from '../pages/Teacher/Teacher';
export { default as Courses } from './Courses';
export { default as CourseDetail } from './CourseDetail';
export { default as QrReader } from './QrReader';
export { default as Follower } from './Follower';
export { default as LogOut } from './LogOut';
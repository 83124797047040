import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "../../src/assets/css/bootstrap.min.css";
import "../../src/assets/css/fontawesome-all.min.css";
import "../../src/assets/css/slick.css";
import "../../src/assets/css/line-awesome.min.css";
import "../../src/assets/css/main.css";
import LogoImage from "../../src/assets/images/all/scan-svgrepo-com 1.svg";
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url, ACCESS_Role_ID, ACCESS_Log_In_User_Id } from '../constants/apiConstants';
import { formatDate, calculateDifference, dateMonthYears, renderStars } from "../pages/Utils";

const Navbar = () => {
  const state = useSelector((state) => state.handleCart);
  const navigate = useNavigate();

  const handleLoginNavigate = () => {
    navigate("/login"); // Navigates to the login screen when the button is clicked
  };
  const handleLogOutNavigate = () => {
    navigate("/logOut"); // Navigates to the login screen when the button is clicked
  };
  const handleNavigateToQRReader = () => {
    navigate("/qrReader"); // Adjust this path to match your route for the QR Reader screen
  };

  return (
    <>
      {/* Preloader */}
      {/* <div className="loader-mask">
        <div className="loader">
          <div></div>
          <div></div>
        </div>
      </div> */}

      {/* Overlay */}
      <div className="overlay"></div>

      {/* Sidebar Overlay */}
      <div className="side-overlay"></div>

      {/* Scroll to Top */}
      <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div>

      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item has-submenu">
                <a href="javascript:void(0)" className="nav-menu__link">
                  Home
                </a>
              </li>
              <li className="nav-menu__item">
                <a href="contact.html" className="nav-menu__link">
                  Contact
                </a>
              </li>
              {/* Add more menu items as needed */}
            </ul>
            <div className="header-right__inner d-lg-none my-3 gap-1 d-flex flx-align">
              <a href="register.html" className="btn btn-main pill">
                <span className="icon-left icon">
                  <img src="assets/images/icons/user.svg" alt="" />
                </span>
                Create Account
              </a>
            </div>
          </div>
        </div>
      </div>

      <img className="shape_top" src="assets/images/all/shape_1.png" alt="" />
      <main className="change-gradient">
        {/* Header */}
        <header>
          <div className="logo"></div>
          {/* Menu Start  */}
          <div className="header-menu d-lg-block d-none">
            <ul className="nav-menu flx-align">
              <li className="nav-menu__item">
                <NavLink
                  exact
                  to="/"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-menu__item">
                <NavLink
                  to="/course"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  Courses
                </NavLink>
              </li>
              <li className="nav-menu__item">
                <NavLink
                  to="/Feed"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  Feed
                </NavLink>
              </li>
              <li className="nav-menu__item">
                <NavLink
                  to="/learn"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  Learn with us
                </NavLink>
              </li>
              <li className="nav-menu__item">
                <NavLink
                  to="/teacher"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  Teachers
                </NavLink>
              </li>
              <li className="nav-menu__item">
                <NavLink
                  to="/institute"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  Institute
                </NavLink>
              </li>
              <li className="nav-menu__item">
                <NavLink
                  to="/about"
                  className="nav-menu__link"
                  activeClassName="active"
                >
                  About Us
                </NavLink>
              </li>
            </ul>
          </div>
          {/* Menu End  */}

          {/* Header Right start */}
          <div className="header-right flx-align pe-lg-5">
            <div className="header-right__inner gap-3 flx-align d-lg-flex d-none">
              {ACCESS_TOKEN_NAME ? (
                <button
                  onClick={handleLogOutNavigate}
                  className="btn btn-main pill sign_btn px-sm-4"
                >
                  Logout
                </button>
              ) : (
                <button
                  onClick={handleLoginNavigate}
                  className="btn btn-main pill sign_btn px-sm-4"
                >
                  Signup/Login
                </button>
              )}
              <a
                onClick={handleNavigateToQRReader}
                className="btn cicrle_btn btn-main"
              >
                <img src={LogoImage} alt="" />
              </a>
            </div>
            <button type="button" className="toggle-mobileMenu d-lg-none">
              <i className="las la-bars"></i>
            </button>
          </div>
          {/* Header Right End  */}
        </header>
      </main>
    </>
  );
};

export default Navbar;

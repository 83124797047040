export { default as AdminNavbar } from './AdminNavbar';
export { default as Adminmain } from './Adminmain';
export { default as AdminProducts } from './AdminProducts';
export { default as AdminFooter } from './AdminFooter';
export { default as AdminLogin } from './AdminLogin';
export { default as AdminDashboard } from './AdminDashboard';

export { default as Header } from './Header';
export {default as CreateVideo} from './CreateVideo';

// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );
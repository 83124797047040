import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Footer, Navbar } from "../components";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME,Image_Base_Url,ACCESS_Role_ID,ACCESS_Log_In_User_Id } from '../constants/apiConstants';


const Follower = () => {
    //const Follower = ({ teacherId, instituteId, userId }) => {
    const userId = ACCESS_Log_In_User_Id; // Replace with dynamic user id
    const teacherId = 1; // Replace with actual teacher id
    const instituteId = 1; // Replace with actual institute id
    const [isFollowing, setIsFollowing] = useState(false);

    
    const follow = async () => {
        const follow = API_BASE_URL + '/follow'
        try {
            const response = await axios.post(follow, {
                user_id: userId,
                teacher_id: teacherId,
                institute_id: instituteId,
            });
            console.log(response.data);
            setIsFollowing(true);
        } catch (error) {
            console.error(error);
        }
    };

    const unfollow = async () => {
        const unfollow = API_BASE_URL + '/unfollow'
        try {
            const response = await axios.post(unfollow, {
                user_id: userId,
                teacher_id: teacherId,
                institute_id: instituteId,
            });
            console.log(response.data);
            setIsFollowing(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
        <div>
            {isFollowing ? (
                <button onClick={unfollow}>Unfollow</button>
            ) : (
                <button onClick={follow}>Follow</button>
            )}
        </div>
        {/* <div>
        <h1>Follow Teacher/Institute</h1>
        <Follower teacherId={teacherId} instituteId={instituteId} userId={userId} />
    </div> */}
    </>
    );
};

export default Follower;

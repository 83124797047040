import React, { useState, useEffect } from 'react';
import '../role/../header/style.css';
//import './styles.css';
import { Header } from "../role";
import { Adminmain, AdminNavbar } from "../role";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { API_BASE_URL, ACCESS_TOKEN_NAME, Image_Base_Url } from '../../../constants/apiConstants';
function ListRole() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [role, setRole] = useState([]); // State to hold roles data

    useEffect(() => {
        const url = API_BASE_URL + '/getRoleData'
        const fetchRoles = async () => {
            try {

                const response = await axios.get(url);
                console.log(response.data);
                setRole(response.data); // Set roles data to state
                console.log(role);
            } catch (err) {
                // setError(err.message); // Handle error

            }
        };

        fetchRoles(); // Call the fetch function
    }, []); // Empty dependency array to run only on mount
    const handelDelete = async (id) => {
        console.log("id : -", id);
        axios.delete(API_BASE_URL + '/deleteRoleDataById/' + id)
            .then(function (response) {
                // console.log(response.data.access_token);
                if (response.status === 200) { 
                    setRole(role.filter((item) => item.id !== id));                  
                    navigate("/admin/listRole");
                }
                else {
                   // props.showError("Username does not exists");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        //setIsLoading(true);
        // try {
        //   const response = await fetch( id, {
        //     method: "DELETE",
        //   });
        //   if (!response.ok) {
        //     throw new Error("Failed to delete item");
        //   }
        //   setUser(user.filter((item) => item.id !== id));
        // } catch (error) {
        //  // setError(error.message);
        // } finally {
        //  // setIsLoading(false);
        // }
    };
    return (
        <div>
            <Header />
            <div className="main-container">
                <AdminNavbar />
                {/* <Adminmain /> */}
                <div className="main">
                    <div className="report-container">
                        <div className="report-header">
                            <h1 className="recent-Articles"> Role List</h1>
                            <Link class="recent-Articles" to={`/admin/createRole`}> Create New Role </Link>
                        </div>

                        <div className="report-body">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {role.map((item, i) => (
                                        <tr key={item.id}>
                                            <td>{i + 1}</td>
                                            <td>{item.role_name}</td>
                                            <td>{item.status}</td>
                                            <td>
                                                <Link to={`/admin/editRole/${item.id}`}>
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </Link>

                                                <i
                                                    className="fa fa-trash-o"
                                                    aria-hidden="true"
                                                    onClick={() => handelDelete(item.id)}
                                                ></i>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* <div className="report-topic-heading">
                                <h3 className="t-op">Article</h3>
                                <h3 className="t-op">Views</h3>
                                <h3 className="t-op">Comments</h3>
                                <h3 className="t-op">Status</h3>
                            </div>

                            <div className="items">
                                <div className="item1">
                                    <h3 className="t-op-nextlvl">Article 73</h3>
                                    <h3 className="t-op-nextlvl">2.9k</h3>
                                    <h3 className="t-op-nextlvl">210</h3>
                                    <h3 className="t-op-nextlvl label-tag">Published</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListRole;
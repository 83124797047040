import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/ProductSlider.css'; // Import the CSS file for styling the slider

// Import images
import image1 from '../../src/assets/images/all/shapaa.png';
import image2 from '../../src/assets/images/all/Untitled-2.png';

// Custom Arrow Components
const PrevArrow = ({ onClick }) => (
  <button className="slick-prev" onClick={onClick}>
    {/* <FontAwesomeIcon icon={faAngleLeft} /> */}
  </button>
);

const NextArrow = ({ onClick }) => (
  <button className="slick-next" onClick={onClick}>
    {/* <FontAwesomeIcon icon={faAngleRight} /> */}
  </button>
);

const ProductSlider = () => {
  // Slider settings
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1500,
    dots: true,
    pauseOnHover: true,
    arrows: true,
    draggable: true,
    infinite: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  // Sample product data
  const products = [
    {
      imageSrc1: image1,
      imageSrc2: image2,
      productName: 'Physics Walla',
    },
    {
      imageSrc1: image1,
      imageSrc2: image2,
      productName: 'Physics Walla',
    },
  ];

  return (
    <div className="container selling-product-slider">
      <Slider {...settings}>
        {products.map((product, index) => (
          <div key={index} className="product-item overlay-none">
            <div className="image-container">
              <a href="#" className="link w-100 product-item_main" onClick={(e) => e.preventDefault()}>
                <img
                  src={product.imageSrc1}
                  alt={`${product.productName} Image 1`}
                  className="cover-img"
                />
              </a>
              <a href="#" className="link w-100 product-item_main_img" onClick={(e) => e.preventDefault()}>
                <img
                  src={product.imageSrc2}
                  alt={`${product.productName} Image 2`}
                  className="cover-img"
                />
              </a>
            </div>
            <a href="#" className="product-item_main_link" onClick={(e) => e.preventDefault()}>
              {product.productName}
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;



import React from 'react';
import images from '../../images/logo.jpg'
const Header = () => {
    return (
        <header>
            <div className="logosec">
                <div className="logo">Padhai-Dekho</div>
                    <img src={images} className="icn menuicn" id="menuicn" alt="menu-icon" />
                    <img src={require('../../images/logo.jpg')} className="icn menuicn" id="menuicn" alt="menu-icon" />

            </div>

            <div className="searchbar">
                
            </div>

            <div className="message">
                <div className="circle"></div>
                <img src=
"https://media.geeksforgeeks.org/wp-content/uploads/20221210183322/8.png"
className="icn"
                    alt="" />
                <div className="dp">
                    <img src=
"https://media.geeksforgeeks.org/wp-content/uploads/20221210180014/profile-removebg-preview.png"
className="dpicn"
                        alt="dp" />
                </div>
            </div>

        </header>
    );
};

export default Header;
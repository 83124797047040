import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from "./redux/store";

import {
  Home,
  AboutPage,
  ContactPage,
  Cart,
  Login,
  Register,
  Checkout,
  PageNotFound,
  Courses,
  CourseDetail,
  QrReader,
  RegisterOtp,
  Follower,
  LogOut,
} from "./pages";
import Teacher from "../src/pages/Teacher/Teacher"
import Studentdashboard from "../src/pages/Student/StudentDashboard"
import {AdminLogin,AdminDashboard,CreateVideo}  from "./components/admin";
import {CreateRole,EditRole,ListRole}  from "./components/admin/role";
import {CreateCourse,EditCourse,ListCourse}  from "./components/admin/course";
import ScrollToTop from "./components/ScrollToTop";
import { Toaster } from "react-hot-toast";
import OtpPage from "./pages/OtpPage";
import Feed from "./pages/Feed";
import FeedDetail from "./pages/FeedDetail";
import CourseVideo from "./pages/CourseVideo";
import Learn from "./pages/Learn";
import CourseCart from "./pages/CourseCart";
import CourseCheckout from './pages/CourseCheckout'
import Upload from "./pages/Upload";
import UploadMedia from "./pages/UploadMedia";
import StudentProfileCourse from './pages/Student/StudentProfileCourse'
import DashboardPost from "./pages/Student/DashboardPost";
import DashboardPostDetail from "./pages/Student/DashboardPostDetail";
import StudentOrderAssign from "./pages/Student/StudentOrderAssign";
import DashboardChat from "./pages/DashboardChat";
import DashboardChatBox from "./pages/DashboardChatBox";
import StudentCertificate from "./pages/Student/StudentCertificate";
import StudentCertificateDetail from "./pages/Student/StudentCertificateDetail";
import TeacherChenal from "./pages/Teacher/TeacherChenal";
import TeacherCourse from "./pages/Teacher/TeacherCourse";
import TeacherChannelBoost from './pages/Teacher/TeacherChannelBoost'
import TeacherProfile from "./pages/Teacher/TeacherProfile";
import StudentChatList from "./pages/Student/StudentChatList";
import StudentChatbot from "./pages/Student/StudentChatbot";
import StudentChatListRemove from "./pages/Student/StudentChatListRemove";
import StudentProfileHistory from "./pages/Student/StudentProfileHistory";
import TeacherChannelBoost1 from "./pages/Teacher/TeacherChannelBoost1";
import TeacherChannelBoost2 from "./pages/Teacher/TeacherChannelBoost2";
import StudentProfile from "./pages/Student/StudentProfile";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={store}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/teacher" element={<Teacher />} />
          <Route path="/course" element={<Courses />} />
          <Route path="/Feed" element={<Feed />} />
          <Route path="/Learn" element={<Learn />} />
          <Route path="/coursecart" element={<CourseCart />} />
          <Route path="/CourseCheckout" element={<CourseCheckout />} />
          <Route path="/Upload" element={<Upload />} />
          <Route path="/UploadMedia" element={<UploadMedia />} />
          <Route path="/CourseVideo" element={<CourseVideo />} />
          <Route path="/CourseDetail/:id" element={<CourseDetail />} />
          <Route path="/feeddetail/:id" element={<FeedDetail />} />
          <Route path="/studentdashboard" element={<Studentdashboard />} />
          <Route path="/DashboardChat" element={<DashboardChat />} />
          <Route path="/TeacherProfile" element={<TeacherProfile />} />
          <Route path="/teacherChannelBoost" element={<TeacherChannelBoost />} />
          <Route path="/teacherChannelBoost1" element={<TeacherChannelBoost1 />} />
          <Route path="/teacherChannelBoost2" element={<TeacherChannelBoost2 />} />
          <Route path="/TeacherCourse" element={<TeacherCourse />} />
          <Route path="/TeacherChenal" element={<TeacherChenal />} />
          <Route path="/studentChatList" element={<StudentChatList />} />
          <Route path="/studentChatbot" element={<StudentChatbot />} />
          <Route path="/studentProfile" element={<StudentProfile />} />
          <Route path="/studentProfileHistory" element={<StudentProfileHistory />} />
          <Route path="/studentChatListRemove" element={<StudentChatListRemove />} />
          <Route path="/StudentCertificateDetail" element={<StudentCertificateDetail />} />
          <Route path="/StudentCertificate" element={<StudentCertificate />} />
          <Route path="/DashboardChatBox" element={<DashboardChatBox />} />
          <Route path="/StudentOrderAssign" element={<StudentOrderAssign />} />
          <Route path="/DashboardPost" element={<DashboardPost />} />
          <Route path="/dashboardPostDetail/:id" element={<DashboardPostDetail />} />
          <Route path="/StudentProfileCourse" element={<StudentProfileCourse />} />
          <Route path="/OtpPage" element={<OtpPage />} />
          <Route path="/RegisterOtp" element={<RegisterOtp />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logOut" element={<LogOut />} />
          <Route path="/register" element={<Register />} />
          <Route path="/registerOtp" element={<RegisterOtp />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/follower" element={<Follower />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/product/*" element={<PageNotFound />} />
          <Route path="/qrReader/*" element={<QrReader />} />


          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          {/* Role route for admin */}
          <Route path="/admin/createRole" element={<CreateRole />} />
          <Route path="/admin/editRole/:id" element={<EditRole />} />
          <Route path="/admin/listRole" element={<ListRole />} />

          {/* Course route for admin */}
          <Route path="/admin/createCourse" element={<CreateCourse />} />
          <Route path="/admin/editCourse/:id" element={<EditCourse />} />
          <Route path="/admin/listCourse" element={<ListCourse />} />
          
          <Route path="/admin/createvideo" element={<CreateVideo />} />
          
          
        </Routes>
      </Provider>
    </ScrollToTop>
    <Toaster />
  </BrowserRouter>
);

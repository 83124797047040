import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/fontawesome-all.min.css";
import "../../assets/css/slick.css";
import "../../assets/css/line-awesome.min.css";
import "../../assets/css/main.css";
import "../../assets/css/responsive.css";

// Importing assets

import { Footer } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import GlobalSearch from "../../components/GlobalSearch";

const StudentOrderAssign = () => {
  const navigate = useNavigate();
  return (
    <div className="bg_dashboard">
      {/* Mobile Menu */}
      <div className="mobile-menu d-lg-none d-block">
        <button type="button" className="close-button">
          <i className="las la-times"></i>
        </button>
        <div className="mobile-menu__inner">
          <div className="mobile-menu__menu">
            <ul className="nav-menu flx-align nav-menu--mobile">
              <li className="nav-menu__item activePage">
                <a href="/" className="nav-menu__link">Home</a>
              </li>
              <li className="nav-menu__item">
                <a href="course.html" className="nav-menu__link">Courses</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Feed</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Videos</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Teachers</a>
              </li>
              <li className="nav-menu__item">
                <a href="/" className="nav-menu__link">Institutes</a>
              </li>
              <li className="nav-menu__item has-submenu">
                <a href="/" className="nav-menu__link">My Profile</a>
                <ul className="nav-submenu">
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Edit Profile</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Enrolled Courses</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Your Posts</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Certificates</a></li>
                  <li className="nav-submenu__item"><a href="/" className="nav-submenu__link">Chats</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <main className="container">
        <div className="row">
          {/* Sidebar */}
          <StudentSidebar/>

          {/* Main Content Area */}
          <div className="col-sm-9 col-md-12 col-xl-9 px-md-5 ps-xl-5">
              <GlobalSearch/>

            <p className="learm-tilte my-3">Assignment Order</p>

            <div className="row p-0 mb-5">
              <div className="col-sm-12">
                <p className="font-weight">
                  Simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type specimen book.
                </p>
              </div>

              <div className="col-sm-12 my-4">
                <div className="progress">
                  <div className="progress-bar bg-main" role="progressbar" style={{ width: "65%" }} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>

            <div className="row">
                    <div className="col-sm-6 mt-4">
                        <p className="profile_text_2 mb-3">Location</p>

                        <div className="position-relative">
                            <input type="url"
                                className="common-input common-input--md dash_borad_input dashboard_input_cust"
                                id="facebookUrl" placeholder="Facebook Profile Url"/>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 mt-3">
                        <p className="profile_text_2 mb-3">Location</p>

                        <div className="position-relative">
                            <input type="url"
                                class="common-input common-input--md dash_borad_input dashboard_input_cust"
                                id="facebookUrl" placeholder="Facebook Profile Url"/>
                        </div>

                    </div>
                </div>

            <div className="row" style={{ marginBottom: "200px" }}>
              <div className="col-sm-6 mt-3">
                <div className="text-md-start mt-3">
                  <a href="#" className="btn btn_cousre w-100">Upload</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default StudentOrderAssign;
